import React, { useState } from "react";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import logo from "../assets/logo.png";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuStyles = {
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
      width: "25%",
      top: 0,
      right: 0,
    },
    bmMenu: {
      background: "#fbbf24",
      width: "100%",
      height: "100%",
      padding: "72px 56px",
      boxSizing: "border-box",
      overflowY: "auto",
    },
    bmBurgerBars: {
      background: "#fff",
      color:"#fff"
    },
    bmCrossButton: {
      height: "24px",
      width: "24px",
      right: "58px",
      top: "24px",
    },
    bmItemList: {
      color: "#fff",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
      width: "100%",
      left: "0",
      top: "0",
    },
  };

  const handleMenuStateChange = (state) => {
    setIsMenuOpen(state.isOpen);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header>
      <nav>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <Menu
          customBurgerIcon={
            <AiOutlineMenu className="menu-burger" onClick={toggleMenu} />
          }
          customCrossIcon={
            <AiOutlineClose className="menu-close" onClick={toggleMenu} />
          }
          right
          isOpen={isMenuOpen}
          onStateChange={handleMenuStateChange}
          styles={menuStyles}
        >
          <Link className="menu-item" to="/about" onClick={closeMenu}>
            HMM360
          </Link>
          <Link className="menu-item" to="/values" onClick={closeMenu}>
            About Us
          </Link>
          <Link className="menu-item" to="/contact" onClick={closeMenu}>
            Contact
          </Link>
        </Menu>
      </nav>
    </header>
  );
};

export default Header;
