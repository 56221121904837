import about from "../assets/video/about-us.mp4"

// const links = [{ name: "Meet our leadership", href: "/" }];
// const stats = [
//   { name: "Institutes Joined", value: "10+" },
//   { name: "Full-time colleagues", value: "300+" },
//   { name: "Hours per week", value: "40" },
//   { name: "Paid time off", value: "10" },
// ];

export default function AboutHMMHero() {
  return (
    <>
      <div className="relative isolate overflow-hidden bg-gray-900 py-32 sm:py-32">
        <video
          autoPlay
          muted
          loop
          className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
        >
          <source src={about} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div
          className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ffff00] to-[#fbbf24] opacity-20"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div
          className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
          aria-hidden="true"
        >
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ffff00] to-[#fbbf24] opacity-20"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8 z-1 relative">
          <div className="mx-auto lg:mx-0">
            <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
              About HMM 360
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Holistic Maturity Model-360 is a resolute model that empowers{" "}
              <br />
              <b>educational institutes</b>, <b>Universities</b>, <b>SMEs</b>,{" "}
              <b>Startups</b> to achieve level <br /> of sophistication,
              effectiveness in delivery and leading <br /> to excellence.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
