import React, { useRef, useEffect, useState, useCallback } from "react";
import Chart from "chart.js/auto";
import { CheckIcon } from "@heroicons/react/20/solid";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { FaStar } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
// import QR from "../assets/img/QR.png";
import { surveyData } from "./questions";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../firebaseConfig";
import Peval from "./Peval";

const Result = ({ surveyResult, userData }) => {
  const chartRef = useRef(null);
  const pdfChartRef = useRef(null);
  const lineChartRef = useRef(null);
  const [rating, setRating] = useState(null);
  //const [l1C1Rating, setL1C1Rating] = useState(null);
  const [l1C2Rating, setL1C2Rating] = useState(null);
  const [l1C1Percantage, setL1C1Percantage] = useState(null);
  const [l1C2Percantage, setL1C2Percantage] = useState(null);
  const [includedFeatures, setIncludedFeatures] = useState([]);
  const [maxMinQues, setMaxMinQues] = useState(null);
  const [pdfData, setPdfData] = useState(null);

  //show ratings- stars
  const renderStars = (rating) => {
    if (rating === undefined || rating === null) {
      return null;
    }
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <FaStar
          key={i}
          className={i <= Math.floor(rating) ? "star-filled" : "star-unfilled"}
        />
      );
    }
    return stars;
  };

  //mapping rating to computable values
  const convertOptionToNumber = (option) => {
    switch (option) {
      case "notInControl":
        return 1;
      case "manageable":
        return 2;
      case "structured":
        return 3;
      case "satisfied":
        return 4;
      case "excellent":
        return 5;
      default:
        return 0;
    }
  };
  //merging the plots and creating a downloadable PDF
  const downloadChart = () => {
    const input1 = document.getElementById("pdf1-container");
    const input2 = document.getElementById("pdf2-container");

    const captureContent = (input) => {
      return html2canvas(input, {
        scale: 1, // Adjust scale for desired resolution
      }).then((canvas) => {
        return canvas;
      });
    };

    const optimizeImage = (dataUrl, quality = 0.5) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = dataUrl;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          const optimizedDataUrl = canvas.toDataURL("image/jpeg", quality);
          resolve(optimizedDataUrl);
        };
      });
    };

    const mergePDFs = async (firstCanvas, secondCanvas) => {
      const pdf = new jsPDF("p", "mm", "a4");
      const firstImageDataUrl = await optimizeImage(
        firstCanvas.toDataURL("image/png")
      );
      const secondImageDataUrl = await optimizeImage(
        secondCanvas.toDataURL("image/png")
      );

      pdf.addImage(firstImageDataUrl, "JPEG", 0, 0, 210, 297);
      pdf.addPage();
      pdf.addImage(secondImageDataUrl, "JPEG", 0, 0, 210, 297);

      return pdf;
    };

    const firstDivPromise = captureContent(input1);
    const secondDivPromise = captureContent(input2);

    Promise.all([firstDivPromise, secondDivPromise])
      .then(async ([firstDivCanvas, secondDivCanvas]) => {
        const mergedPDF = await mergePDFs(firstDivCanvas, secondDivCanvas);
        mergedPDF.save("hmm.pdf");
      })
      .catch((error) => {
        console.error("Error while capturing or merging PDFs:", error);
      });
  };

  //IDK WHAT THIS IS- funtion to extract documents from some database
  const getAllDataDB = useCallback(async () => {
    const querySnapshot = await getDocs(collection(firestore, "data"));

    const data = [];
    querySnapshot.forEach((doc) => {
      const d = doc.data();
      const res = Object.values(d?.result).map((e) => {
        return convertOptionToNumber(e);
      });
      data.push(res);
      // console.log(res);
    });
    return data;
  }, []);

  //calculate the avg of each array and store as array of avgs
  function calculateAverageOfArrays(arrays) {
    const length = arrays[0].length;

    for (let arr of arrays) {
      if (arr.length !== length) {
        // Arrays should be of equal length
        throw new Error("Arrays must be of equal length");
      }
    }

    const result = [];

    for (let i = 0; i < length; i++) {
      let sum = 0;
      for (let arr of arrays) {
        sum += arr[i];
      }
      const average = sum / arrays.length;
      result.push(average);
    }

    return result;
  }

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const values = Object.values(surveyResult).map((e) => {
        return convertOptionToNumber(e);
      });

      const surveyNumberData = {};
      let sum = 0;
      let len = 0;

      for (const key in surveyResult) {
        if (surveyResult.hasOwnProperty(key)) {
          const value = convertOptionToNumber(surveyResult[key]);
          surveyNumberData[key] = value;
          sum += value;
          len++;
        }
      }

      // Create the dictionary where the key is the label and the value is the corresponding surveyNumberData value
      const labelValueDict = {};
      surveyData.forEach((item) => {
        const surveyNumberValue = surveyNumberData[item.question];
        if (surveyNumberValue !== undefined) {
          labelValueDict[item.label] = surveyNumberValue;
        }
      });

      // Convert labelValueDict to an array of key-value pairs, sort by value, and convert back to an object
      const sortedLabelValueArray = Object.entries(labelValueDict).sort(
        ([, a], [, b]) => a - b
      );
      // const sortedLabelValueDict = Object.fromEntries(sortedLabelValueArray);

      setL1C1Percantage(+((sum * 100) / 75).toFixed(1));
      setRating(+(sum / len).toFixed(1));

      let maxKeys = [];
      let minKeys = [];
      let maxValues = [-Infinity, -Infinity];
      let minValues = [Infinity, Infinity];
      for (const key in surveyNumberData) {
        if (surveyNumberData.hasOwnProperty(key)) {
          const value = surveyNumberData[key];
          if (value > maxValues[0]) {
            maxValues[1] = maxValues[0];
            maxValues[0] = value;
            maxKeys[1] = maxKeys[0];
            maxKeys[0] = key;
          } else if (value > maxValues[1]) {
            maxValues[1] = value;
            maxKeys[1] = key;
          }

          if (value < minValues[0]) {
            minValues[1] = minValues[0];
            minValues[0] = value;
            minKeys[1] = minKeys[0];
            minKeys[0] = key;
          } else if (value < minValues[1]) {
            minValues[1] = value;
            minKeys[1] = key;
          }
        }
      }

      const rat = Math.floor(l1C2Rating);
      const minAns = [];
      const maxAns = [];
      minKeys.forEach((res) => {
        minAns.push(
          surveyData.find((r) => r.question === res).optionAnswers[rat - 1]
        );
      });
      maxKeys.forEach((res) => {
        maxAns.push(
          surveyData.find((r) => r.question === res).optionAnswers[rat - 1]
        );
      });

      console.log({ minKeys: minAns, maxKeys: maxAns });
      setMaxMinQues({ minKeys: minAns, maxKeys: maxAns });

      let newArray = [];
      newArray.push(
        "Strengths are in the sectors of " +
          sortedLabelValueArray[14][0] +
          " and " +
          sortedLabelValueArray[13][0]
      );
      newArray.push(
        "Areas for improvement are needed in the sectors of " +
          sortedLabelValueArray[0][0] +
          " and " +
          sortedLabelValueArray[1][0]
      );
      newArray.push(
        "Strengths are in the sectors of " +
          sortedLabelValueArray[12][0] +
          " and " +
          sortedLabelValueArray[11][0]
      );
      newArray.push(
        "Areas for improvement are needed in the sectors of " +
          sortedLabelValueArray[2][0] +
          " and " +
          sortedLabelValueArray[3][0]
      );
      setIncludedFeatures(newArray);

      const indxs = Object.keys(surveyResult).map(
        (question, index) => +index + 1
      );

      const chartData = {
        labels: indxs,
        datasets: [
          {
            label: "Result",
            data: values,
            backgroundColor: "rgba(251, 191, 36, 0.2)",
            borderColor: "#fbbf24",
            borderWidth: 1,
          },
        ],
      };

      const chartOptions = {
        tooltips: {
          callbacks: {
            // chk .txt
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          r: {
            suggestedMin: 0,
            suggestedMax: 5,
            ticks: {
              display: false,
            },
          },
        },
      };

      const radarChart = new Chart(chartRef.current, {
        type: "radar",
        data: chartData,
        options: chartOptions,
      });

      const evaluations = Object.entries(surveyResult).map(
        ([name, rating]) => ({
          name: surveyData.filter((res) => res.question === name)[0]?.label,
          rating: convertOptionToNumber(rating),
        })
      );

      setPdfData({
        name: userData.name,
        email: userData.email,
        category: userData.category,
        evaluations: evaluations,
        ...userData,
      });

      return () => {
        radarChart.destroy();
      };
    }
  }, [surveyResult, userData, l1C2Rating]);

  useEffect(() => {
    if (pdfChartRef && pdfChartRef.current) {
      const values = Object.values(surveyResult).map((e) => {
        return convertOptionToNumber(e);
      });

      const indxs = Object.keys(surveyResult).map(
        (question, index) => +index + 1
      );

      const chartData = {
        labels: indxs,
        datasets: [
          {
            label: "Result",
            data: values,
            backgroundColor: "rgba(251, 191, 36, 0.2)",
            borderColor: "#fbbf24",
            borderWidth: 1,
          },
        ],
      };

      const chartOptions = {
        tooltips: {
          callbacks: {
            // chk.txt
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          r: {
            suggestedMin: 0,
            suggestedMax: 5,
            ticks: {
              display: false,
            },
          },
        },
      };
      const pdfRadarChart = new Chart(pdfChartRef.current, {
        type: "radar",
        data: chartData,
        options: chartOptions,
      });

      return () => {
        pdfRadarChart.destroy();
      };
    }
  }, [pdfData, surveyResult]);

  useEffect(() => {
    const fetchData = async () => {
      if (lineChartRef && lineChartRef.current) {
        try {
          const data = await getAllDataDB();
          const finalArray = calculateAverageOfArrays(data);

          const values = Object.values(surveyResult).map((e) => {
            return convertOptionToNumber(e);
          });

          const indxs = Object.keys(surveyResult).map(
            (question, index) => +index + 1
          );

          let sum = 0;
          let len = finalArray.length;

          finalArray.forEach((val) => {
            sum += val;
          });

          const perc = Math.floor((sum * 100) / 75).toFixed(1);
          setL1C2Percantage(perc);
          setL1C2Rating(+(sum / len).toFixed(1));

          const chartData = {
            labels: indxs,
            datasets: [
              {
                label: "Result",
                data: values,
                backgroundColor: "rgba(251, 191, 36, 0.4)",
                borderColor: "#fbbf24",
                borderWidth: 1,
              },
              {
                label: "Overall",
                data: finalArray,
                backgroundColor: "rgba(251, 251, 251, 0.8)",
                borderColor: "#010101",
                borderWidth: 1,
              },
            ],
          };

          const chartOptions = {
            tooltips: {
              callbacks: {},
            },
            responsive: true,
            interaction: {
              mode: "index",
              intersect: false,
            },
            stacked: false,
            plugins: {
              title: {
                display: true,
              },
            },
            scales: {
              y: {
                type: "linear",
                display: true,
                position: "left",
              },
              y1: {
                type: "linear",
                display: true,
                position: "right",
                grid: {
                  drawOnChartArea: false,
                },
              },
            },
          };

          const lineChart = new Chart(lineChartRef.current, {
            type: "line",
            data: chartData,
            options: chartOptions,
          });

          return () => {
            lineChart.destroy();
          };
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [pdfData, surveyResult, getAllDataDB]);

  const navigate = useNavigate();

  const handleDetailedResult = () => {
    console.log(surveyData);
    navigate("/detailed-result", { state: { surveyResult, surveyData } });
  };

  return (
    <>
      {/*backgroung/css effects*/}
      <div className="relative px-6 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ffff00] to-[#FFD700] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>

      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          {/*div for page header of downloadable pdf*/}
          <div className="mx-auto sm:text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              HMM 360 Assessment Results
            </h2>
            <p className="mt-6 text-sm leading-8 text-gray-600 max-w-7xl">
              Thank you for taking a free trial of HMM 360 - Level 1 <br />
              This is a high-level understanding of how you as an individual
              perceive the current state. <br />
              Subscribe - To understand the aggregated score of all leads on
              where we are today. <br />
              You can also deep dive to L2 & L3 for more detailed understanding.{" "}
              <br />
              If the numbers are not a true reflection, then the team has to
              work on creating the right perception. <br />
              If the numbers are true, then conduct a validation workshop with
              the team to define a specific improvement action plan.
            </p>
          </div>

          {/*div for body of the results*/}
          <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
            <div className="p-8 sm:p-10 lg:flex-auto">
              {/*header of results*/}
              <h3 className="text-2xl font-bold tracking-tight text-gray-900">
                Key Findings
              </h3>
              <p className="mt-6 text-base leading-7 text-gray-600">
                Here are some key insights from the assessment:
              </p>

              {/*div for overall ratings */}
              <div className="mt-10 flex items-center gap-x-4">
                <h4 className="flex-none text-sm font-semibold leading-6 text-[#FFD700]">
                  Overall Rating
                </h4>
                <div className="h-px flex-auto bg-gray-100" />
              </div>

              {/*div to display star ratings */}
              <div className="mx-auto max-w-sm">
                <h3
                  className={`mt-16 leading-7 text-8xl font-bold ${
                    rating < 3 ? "text-red-600" : "text-green-600"
                  }`}
                >
                  {rating?.toFixed(2)}
                </h3>
                <div className="flex gap-1 mt-12 text-4xl ">
                  {renderStars(rating)}
                </div>
                <p className="mt-6 leading-7 text-base text-slate-600">
                  Out of 5 Stars
                </p>
              </div>

              {/*div to display radar chart */}
              <div className="mt-10 flex items-center gap-x-4 justify-between">
                <div className="flex gap-x-4 items-center">
                  <h4 className="flex-none text-sm font-semibold leading-6 text-[#000] bg-[#FFD700] p-2 rounded">
                    Strengths
                  </h4>
                </div>
                <div className="h-px flex-auto bg-gray-100" />
                <div className="flex gap-x-4 items-center">
                  <h4 className="flex-none text-sm font-semibold leading-6 text-[#000]  bg-[#FFD700] p-2 rounded">
                    Improvements
                  </h4>
                </div>
              </div>

              {/*table to display department and ratings- MAYBE */}
              <ul className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
                {includedFeatures.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      className="h-6 w-5 flex-none text-[#FFD700]"
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>

            {/*download button and extra info */}
            <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0 ">
              <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center">
                <div className="mx-auto max-w-sm">
                  <canvas
                    onClick={handleDetailedResult}
                    ref={chartRef}
                  ></canvas>
                  <button
                    onClick={downloadChart}
                    className="mt-10 block w-full rounded-md bg-[#FFD700] px-3 py-2 text-center text-sm font-semibold text-zinc-950 shadow-sm hover:bg-amber-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
                  >
                    Download Chart
                  </button>
                  <p className="mt-6 font-bold text-md leading-5 text-gray-600">
                    Develop actionable strategies and initiatives based on
                    assessment findings to drive continuous improvement and
                    organizational success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*extra info- promote next levels */}
        <div className="flex flex-col w-full p-5 justify-center items-center">
          <p>Need more information?</p>
          <br />
          <Link to="/level-2">
            <button className="text-white bg-amber-500 border-0 py-2 px-6 focus:outline-none hover:bg-amber-600 rounded text-lg">
              Try Level 2
            </button>
          </Link>
        </div>
      </div>
      {/* chk .txt */}

      {/* chk .txt */}

      {/*pdf content page 1 div*/}
      <div
        id="pdf1-container"
        className="absolute -top-[300%]"
        style={{
          background: "#fff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ padding: 24, border: "1px solid #f3bb2f", width: 800 }}>
          {/*top introductory header */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "8px 16px",
              border: "3px solid #8c442a",
              background: "#000",
            }}
          >
            <div>
              <img src={logo} width={60} alt="" />
            </div>
            <div>
              <span style={{ color: "#fff", fontSize: 38, fontWeight: 600 }}>
                HOLISTIC MATURITY MODEL - 360<span>&deg;</span>
              </span>
              <br />
              <span style={{ float: "right", color: "#fff" }}>
                Empowering to Accelerate
              </span>
            </div>
          </div>
          {/*details of the user */}
          <div
            style={{
              border: "2px solid #f3bb2f",
              padding: "8px 16px",
              margin: "12px 0",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ width: "40%" }}>
                <p style={{ color: "#8c442a", fontSize: 16, fontWeight: 700 }}>
                  Institution Name :
                </p>
                <p style={{ color: "#8c442a", fontSize: 16, fontWeight: 700 }}>
                  Representive Name :
                </p>
                <p style={{ color: "#8c442a", fontSize: 16, fontWeight: 700 }}>
                  Representive Designation :
                </p>
                <p style={{ color: "#8c442a", fontSize: 16, fontWeight: 700 }}>
                  Assessment Heirarchy :
                </p>
              </div>
              <div style={{ width: "60%" }}>
                <p style={{ color: "#888d91", fontSize: 16, fontWeight: 500 }}>
                  {pdfData?.institute}
                </p>
                <p style={{ color: "#888d91", fontSize: 16, fontWeight: 500 }}>
                  {userData?.name}
                </p>
                <p style={{ color: "#888d91", fontSize: 16, fontWeight: 500 }}>
                  {pdfData?.representativeDesignation}
                </p>
                <p style={{ color: "#888d91", fontSize: 16, fontWeight: 500 }}>
                  {pdfData?.assessmentHierarchy}
                </p>
              </div>
            </div>
          </div>
          {/*single row tale to display overall rating- self appraisal */}
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              borderTop: "2px solid #f3bb2f",
              borderLeft: "2px solid #f3bb2f",
              borderRight: "2px solid #f3bb2f",
            }}
          >
            <thead>
              <tr style={{ backgroundColor: "#000" }}>
                <th
                  style={{
                    border: "1px solid #ddd",
                    padding: 8,
                    textAlign: "center",
                    width: "80%",
                    color: "#fff",
                    fontWeight: 600,
                    letterSpacing: "2.5px",
                  }}
                >
                  Overall Rating - Self Appraisal - L1 C1
                </th>
                <th
                  style={{
                    border: "1px solid #ddd",
                    padding: 8,
                    textAlign: "center",
                    width: "20%",
                    color: "#fff",
                    fontWeight: 600,
                  }}
                >
                  {rating}/5{" "}
                  <span style={{ color: "#f3bb2f" }}>({l1C1Percantage}%)</span>
                </th>
              </tr>
            </thead>
          </table>
          {/*table to display the department ratings and radar chart*/}
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              border: "2px solid #f3bb2f",
            }}
          >
            <thead>
              <tr style={{ backgroundColor: "#f3bb2f" }}>
                <th
                  style={{
                    border: "1px solid #ddd",
                    padding: 8,
                    textAlign: "center",
                    width: "5%",
                  }}
                >
                  Id
                </th>
                <th
                  style={{
                    border: "1px solid #ddd",
                    padding: 8,
                    textAlign: "left",
                    width: "35%",
                  }}
                >
                  Department
                </th>
                <th
                  style={{
                    border: "1px solid #ddd",
                    padding: 8,
                    textAlign: "center",
                    width: "10%",
                  }}
                >
                  Rating{" "}
                </th>
                <th
                  style={{
                    border: "1px solid #ddd",
                    padding: 8,
                    textAlign: "center",
                    width: "50%",
                  }}
                />
              </tr>
            </thead>
            <tbody>
              {pdfData?.evaluations.map((evaluation, index) => (
                <tr key={index}>
                  <td style={{ border: "1px solid #ddd", padding: 4, textAlign:"center" }}>
                    {index + 1}
                  </td>
                  <td style={{ border: "1px solid #ddd", padding: 4, textAlign:"left" }}>
                    {evaluation.name.toString().toUpperCase()}
                  </td>
                  <td style={{ border: "1px solid #ddd", padding: 4, textAlign:"center" }}>
                    {evaluation.rating}
                  </td>
                  {index === 0 && (
                    <td
                      rowSpan={15}
                      style={{ border: "1px solid #ddd", padding: 8, textAlign:"center" }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          // backgroundColor: "#ddd",
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            height: "100%",
                            // backgroundColor: "#f3bb2f",
                            width: "90%",
                            // position: "absolute",
                          }}
                        >
                          <canvas ref={pdfChartRef} />
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
              {/* chk txt */}
            </tbody>
          </table>
          {/*single row tale to display group appraisal */}
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              border: "2px solid #f3bb2f",
              margin: "12px 0",
            }}
          >
            <thead>
              <tr style={{ backgroundColor: "#000" }}>
                <th
                  style={{
                    border: "1px solid #ddd",
                    padding: 8,
                    textAlign: "left",
                    width: "80%",
                    color: "#fff",
                    fontWeight: 600,
                    letterSpacing: "2.5px",
                  }}
                >
                  Overall Rating - Group Appraisal - L1 C2
                </th>
                <th
                  style={{
                    border: "1px solid #ddd",
                    padding: 8,
                    textAlign: "left",
                    width: "20%",
                    color: "#fff",
                    fontWeight: 600,
                    letterSpacing: "2.5px",
                  }}
                >
                  {l1C2Rating}/5{" "}
                  <span style={{ color: "#ff0000" }}>({l1C2Percantage}%)</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  colSpan={2}
                  style={{ border: "1px solid #ddd", padding: 8 }}
                >
                  <div id="legend-container" />
                  <canvas ref={lineChartRef} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/*pdf content page 2 div*/}
      <div
        id="pdf2-container"
        className="absolute -top-[300%]"
        style={{
          background: "#fff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ padding: 24, border: "1px solid #f3bb2f", width: 800 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "8px 16px",
              border: "3px solid #8c442a",
              background: "#000",
            }}
          >
            <div>
              <img src={logo} width={60} alt="" />
            </div>
            <div>
              <span style={{ color: "#fff", fontSize: 38, fontWeight: 600 }}>
                HOLISTIC MATURITY MODEL - 360<span>&deg;</span>
              </span>
              <br />
              <span style={{ float: "right", color: "#fff" }}>
                Empowering to Accelerate
              </span>
            </div>
          </div>
          {/*user details */}
          <div
            style={{
              border: "2px solid #f3bb2f",
              padding: "8px 16px",
              margin: "12px 0",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ width: "40%" }}>
                <p style={{ color: "#8c442a", fontSize: 16, fontWeight: 700 }}>
                  Institution Name :
                </p>
                <p style={{ color: "#8c442a", fontSize: 16, fontWeight: 700 }}>
                  Representive Name :
                </p>
                <p style={{ color: "#8c442a", fontSize: 16, fontWeight: 700 }}>
                  Representive Designation :
                </p>
                <p style={{ color: "#8c442a", fontSize: 16, fontWeight: 700 }}>
                  Assessment Heirarchy :
                </p>
              </div>
              <div style={{ width: "60%" }}>
                <p style={{ color: "#888d91", fontSize: 16, fontWeight: 500 }}>
                  {pdfData?.institute}
                </p>
                <p style={{ color: "#888d91", fontSize: 16, fontWeight: 500 }}>
                  {userData?.name}
                </p>
                <p style={{ color: "#888d91", fontSize: 16, fontWeight: 500 }}>
                  {pdfData?.representativeDesignation}
                </p>
                <p style={{ color: "#888d91", fontSize: 16, fontWeight: 500 }}>
                  {pdfData?.assessmentHierarchy}
                </p>
              </div>
            </div>
          </div>
          <div>
            <p style={{ color: "#8c442a", fontSize: 12, textAlign: "center" }}>
              * For cross validation of scores, you may go for L1 C3 appraisal
              by external team{"{"}" "{"}"}
            </p>
          </div>

          {/*complete introspection grid part of the page */}
          <div style={{ width: "100%", margin: "12px 0" }}>
            {/* <div>
              <div style={{ backgroundColor: "#000" }}>
                <p
                  style={{
                    padding: 8,
                    textAlign: "center",
                    width: "100%",
                    color: "#f3bb2f",
                    fontWeight: 600,
                    fontSize: 18,
                    letterSpacing: "2.5px",
                  }}
                >
                  INTROSPECTION GRID
                </p>
              </div>
            </div> */}
            {/*introspection grid- MUST NOT NEED FROM HERE TILL END OF THIS div */}
            {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              
              <Peval />
            </div> */}

            {/* <div
  style={{
    border: "2px solid #f3bb2f",
    padding: "8px 16px",
    margin: "12px 0",
    overflow: "auto", // Added overflow property
  }}
>
  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap" }}>
    <Peval />
  </div>
</div> */}

            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                border: "2px solid #f3bb2f",
                margin: "12px 0",
              }}
            >
              <thead>
                <tr style={{ backgroundColor: "#000" }}>
                  <th
                    style={{
                      padding: 8,
                      textAlign: "center",
                      width: "100%",
                      color: "#f3bb2f",
                      fontWeight: 600,
                      fontSize: 18,
                      letterSpacing: "2.5px",
                    }}
                  >
                    INTROSPECTION GRID
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    colSpan={2}
                    style={{ border: "1px solid #ddd", padding: 8, textAlign:"center" }}
                  >
                    <div id="legend-container" />
                    <Peval
                      pdfData={pdfData}
                      userData={userData}
                      avgSelfAppraisal={rating} // Pass L1C1Rating as avgSelfAppraisal
                      avgCrossAppraisal={l1C2Rating} // Pass L1C2Rating as avgCrossAppraisal
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/*key takewawys */}
          <div style={{ width: "100%", margin: "12px 0" }}>
            <div>
              <div style={{ backgroundColor: "#000" }}>
                <p
                  style={{
                    padding: 8,
                    textAlign: "center",
                    width: "100%",
                    color: "#f3bb2f",
                    fontWeight: 600,
                    fontSize: 18,
                    letterSpacing: "2.5px",
                  }}
                >
                  KEY TAKEAWAYS
                </p>
              </div>
              <div
                style={{
                  border: "2px solid #f3bb2f",
                  padding: 8,
                  background: "#eee",
                }}
              >
                <ol>
                  <li style={{ margin: "8px 0" }}>{maxMinQues?.maxKeys[0]}</li>
                  <li style={{ margin: "8px 0" }}>{maxMinQues?.maxKeys[1]}</li>
                </ol>
              </div>
            </div>
          </div>
          {/*recommendations */}
          <div style={{ width: "100%", margin: "12px 0" }}>
            <div>
              <div style={{ backgroundColor: "#000" }}>
                <p
                  style={{
                    padding: 8,
                    textAlign: "center",
                    width: "100%",
                    color: "#f3bb2f",
                    fontWeight: 600,
                    fontSize: 18,
                    letterSpacing: "2.5px",
                  }}
                >
                  RECOMMENDATIONS
                </p>
              </div>
              <div
                style={{
                  border: "2px solid #f3bb2f",
                  padding: 8,
                  background: "#eee",
                }}
              >
                <ol>
                  <li style={{ margin: "8px 0" }}>{maxMinQues?.minKeys[0]}</li>
                  <li style={{ margin: "8px 0" }}>{maxMinQues?.minKeys[1]}</li>
                  {/* <li style={{ margin: "8px 0" }}>
                    Create a stronger execution plan and drive the closure
                    through a program management team.
                  </li> */}
                </ol>
              </div>
            </div>
          </div>
          {/*footer of final page- with text */}
          <div style={{ width: "100%", margin: "12px 0" }}>
            <p
              style={{
                letterSpacing: 1,
                color: "#777",
                fontSize: 14,
                fontWeight: 400,
                lineHeight: "normal",
              }}
            >
              For detailed L2, L3 assessment you may contact HMM 360 team which
              will involve baselining, strategic improvement plan, robust
              governance plan through a collaborative focused groups
              interactions and leadership design thinking workshop. For Queries:
              Contact Us at{" "}
              <a style={{ color: "blue" }} href="tel:+919880979480">
                +91 9880979480
              </a>
              , mail us at:{" "}
              <a style={{ color: "blue" }} href="mailto:info@hmm360.in">
                info@hmm360.in
              </a>
            </p>
          </div>
          <hr />
          {/*footer of final page */}
          <div style={{ width: "100%", margin: "12px 0" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  background: "#000",
                  borderRadius: 8,
                  padding: "6px 12px",
                }}
              >
                <img src={logo} width="120px" alt="logo" />
              </div>
              <div>
                <span>
                  Enabled &amp; Powered by &nbsp;
                  <a
                    href="https://formatiqs.in/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "blue" }}
                  >
                    formatiqs.in
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Result;
