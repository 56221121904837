import hero1 from "../assets/video/hmm.gif";
import { useInView } from "react-intersection-observer";
import hmmSamplePDF from "../assets/pdf/hmmSample.pdf";
import { useNavigate } from "react-router-dom";

export default function Hero() {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const navigate = useNavigate();

  const handleSurveyLink = () => {
    navigate("/survey");
  };

  const downloadSample = () => {
    const anchor = document.createElement("a");
    anchor.href = hmmSamplePDF;
    anchor.download = "hmmSample.pdf";
    anchor.click();
  };

  return (
    <div className="hero-content-section">
      <div className="relative bg-transparent px-6 lg:px-8 flex flex-col lg:flex-row items-center justify-center">
        <div
          className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
          aria-hidden="true"
        >
          <div
            className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] opacity-10"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="lg:w-1/2 text-left relative z-10">
          <div className="mx-auto max-w-2xl relative z-10">
            <div className="hidden sm:mb-8 sm:flex sm:justify-left">
              <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-white ring-1 ring-amber-900/60 hover:ring-amber-900/80">
                Empowering to Accelerate.{" "}
              </div>
            </div>
            <div className="text-left">
              <h1
                ref={ref}
                className={`text-4xl font-bold tracking-tight text-white sm:text-6xlt ${
                  inView ? "animate__animated animate__fadeInUp" : ""
                }`}
              >
                Achieve Top Quartile Performance
              </h1>
              <p className="mt-6 text-base leading-7 text-white">
                HMM360 (Holistic Maturity Model) empowers{" "}
                <b>educational institutes</b> , <br /> <b>Universities</b>,{" "}
                <b>SMEs</b> and <b>Startups</b> to self assess, baseline and
                introspect the current health of business and drive effective
                delivery for continous improvement to achieve top quartile
                performance.
              </p>
              <p className="mt-6 text-lg leading-8 text-amber-400">
                Remember - What gets measured gets improved
              </p>
            </div>

            <div className="mt-10">
              <button className="downloadSample mt-8" onClick={downloadSample}>
                Download Sample
              </button>

              &nbsp;&nbsp;
              <button
                className="downloadSample mt-8"
                onClick={handleSurveyLink}
              >
                Take a Free Trial
              </button>
            </div>
          </div>
        </div>
        <div
          ref={ref}
          className={`lg:w-1/2 justify-end flex ${
            inView ? "animate__animated animate__fadeInUp" : ""
          }`}
        >
          <img src={hero1} alt="hero" />
        </div>
      </div>
    </div>
  );
}
