import { useLocation } from "react-router-dom";
import Footer from "../layout/footer";
import Header from "../layout/header";

const ratingToNumber = (rating) => {
  switch (rating) {
    case "excellent":
      return 5;
    case "satisfied":
      return 4;
    case "structured":
      return 3;
    case "manageable":
      return 2;
    case "notInControl":
      return 1;

    default:
      return 0;
  }
};

export default function DetailedResult(props) {
  const { state } = useLocation();
  const { surveyData, surveyResult } = state;
  return (
    <>
      <Header />
      <section className="text-white body-font overflow-hidden">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-20">
            <h1 className="sm:text-4xl text-3xl font-medium title-font mb-2 text-white">
              Detailed Result at level 2
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-white">
            HMM360 provides an in-depth analysis with specific insights and comprehensive data.
            </p>
          </div>
          <div className="flex flex-wrap -m-4">
            <div className="p-6 bg-gray-800 text-white min-h-screen">
              <div className="overflow-x-auto">
                <table className="min-w-full bg-gray-900 border border-gray-700 rounded-lg">
                  <thead className="bg-gray-700">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                        ID
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                        L1
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                        Rating
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                        Deep dive into performance
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(surveyResult).map(
                      ([question, response], index) => (
                        <tr key={question} className="border-b border-gray-700">
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                            {index + 1}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                            {surveyData[index].label}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm">
                            {ratingToNumber(response)}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium">
                            {
                              surveyData.find((r, i) => i === index)
                                .optionAnswers[ratingToNumber(response) - 1]
                            }
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
