import hmm1 from "../assets/img/hmm-1.png";
import hmm2 from "../assets/img/hmm-2.png";
import hmm3 from "../assets/img/hmm-3.png";
import { Link } from "react-router-dom";
import {
  LightBulbIcon,
  CircleStackIcon,
  HandRaisedIcon,
  SparklesIcon,
  CheckBadgeIcon,
} from "@heroicons/react/24/outline";

export default function About() {
  return (
    <>
      <div className="relative isolate overflow-hidden bg-white py-12 sm:py-24 lg:overflow-visible px-6 lg:px-0">
        <div className="absolute inset-0 -z-10 overflow-hidden">
          <svg
            className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
            />
          </svg>
        </div>

        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
          <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-1 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
            <img
              className="w-[37rem] max-w-none bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[37rem]"
              src={hmm1}
              alt="hmm1"
              style={{borderTopRightRadius:"12px", borderBottomRightRadius:"12px"}}
            />
          </div>
          <div className="lg:col-span-2 lg:col-start-2 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-7xl lg:grid lg:grid-cols-1 lg:gap-x-8 lg:px-8">
            <div className="lg:pl-4">
              <div className="lg:max-w-lg lg:w-full">
                <p className="text-base font-semibold leading-7 text-[#fbbf24]">
                  Our Mission
                </p>
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Empowering Excellence
                </h1>
                <p className="mt-6 text-base leading-7 text-gray-700">
                  Our mission is to guide you on the journey to becoming top
                  quartile performer and it always start with the first step of
                  assessing where you are today as saying goes, “What gets
                  measured get managed.
                </p>
                <p className="mt-6 text-base leading-7 text-gray-700">
                  To help you, we have designed a maturity model combining our
                  rich experience of executing countless time with number of
                  clients and hand holding them on the journey and help achieve
                  their potential to reach top quartile performance.
                </p>
              </div>

              <div className="mt-10 flex gap-x-6">
                <Link
                  to="/survey"
                  className="rounded-md bg-[#fbbf24] px-3.5 py-2.5 text-sm font-semibold text-zinc-950 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
                >
                  Try Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative isolate overflow-hidden bg-fuchsia-50 py-12 sm:py-24 px-6 lg:overflow-visible lg:px-0">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="max-w-xl lg:max-w-lg">
                <div className="lg:max-w-lg">
                  <p className="text-base font-semibold leading-7 text-[#fbbf24]">
                    Our Values
                  </p>
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Trusted Partner in Process Maturity Execution
                  </h1>
                </div>

                <ul className="mt-8 space-y-8 text-gray-600">
                  <li className="flex gap-x-3">
                    <CircleStackIcon
                      className="mt-1 h-5 w-5 flex-none text-amber-400"
                      aria-hidden="true"
                    />
                    <span>
                      <strong className="font-semibold text-gray-900">
                        Integrity.
                      </strong>{" "}
                      Uphold honesty, transparency, and ethical conduct and
                      build trust with stakeholders by consistently adhering to
                      high standards.
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    <HandRaisedIcon
                      className="mt-1 h-5 w-5 flex-none text-amber-400"
                      aria-hidden="true"
                    />
                    <span>
                      <strong className="font-semibold text-gray-900">
                        Collaboration.
                      </strong>{" "}
                      Foster a collaborative environment where students,
                      faculty, and staff work together. Encourage
                      interdisciplinary cooperation and knowledge sharing.
                    </span>
                  </li>
                  <li className="flex gap-x-3">
                    <LightBulbIcon
                      className="mt-1 h-5 w-5 flex-none text-amber-400"
                      aria-hidden="true"
                    />
                    <span>
                      <strong className="font-semibold text-gray-900">
                        Innovation.
                      </strong>{" "}
                      Promote innovative teaching methods, curriculum design,
                      and student engagement. Explore new ways to enhance the
                      learning experience.
                    </span>
                  </li>

                  <li className="flex gap-x-3">
                    <SparklesIcon
                      className="mt-1 h-5 w-5 flex-none text-amber-400"
                      aria-hidden="true"
                    />
                    <span>
                      <strong className="font-semibold text-gray-900">
                        Data-Driven Decision Making.
                      </strong>{" "}
                      Base decisions on evidence and data. Regularly assess
                      performance metrics, student outcomes, and institutional
                      effectiveness.
                    </span>
                  </li>

                  <li className="flex gap-x-3">
                    <CheckBadgeIcon
                      className="mt-1 h-5 w-5 flex-none text-amber-400"
                      aria-hidden="true"
                    />
                    <span>
                      <strong className="font-semibold text-gray-900">
                        Quality Assurance.
                      </strong>{" "}
                      Implement rigorous quality assurance processes. Regularly
                      evaluate programs, teaching effectiveness, and student
                      satisfaction.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
            <img
              className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[42rem]"
              src={hmm2}
              alt="hmm2"
            />
          </div>
        </div>
        <div className="absolute inset-0 -z-10 overflow-hidden">
          <svg
            className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
            />
          </svg>
        </div>
      </div>

      {/* <div className="relative isolate overflow-hidden bg-white py-12 sm:py-24 lg:overflow-visible px-6 lg:px-0">
        <div className="absolute inset-0 -z-10 overflow-hidden">
          <svg
            className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
            />
          </svg>
        </div>
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="lg:max-w-lg">
                <p className="text-base font-semibold leading-7 text-[#fbbf24]">
                  Our Approach
                </p>
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Holistic Maturity Model-360
                </h1>
                <p className="mt-6 text-base leading-7 text-gray-700">
                HMM 360 covers wide range of services and highly customisable and agile in delivery and will help customers reach top quartile performance. The HMM-360 offerings cover across the spectrum of your needs, If you are looking to only have pulse check, which will set the rhythm of your change management you can do that. If your agenda is to go beyond Pulse check, focus on key areas of improvements, do a deep diagnostic, and implement full scale transformation, it can also be achieved through various phases. HMM-360 cuts across the myriad processes, verticals and industries and helps you in the discovery process and undertake review of your Digital capabilities, identify opportunities for improvements, initiating Change Management, Leadership Development, etc. with an aim to successfully lead the Transformative Journey. HMM-360 will help all your efforts culminate in overall improved quality of products and services delivery, enables value to customers and reach the top quartile performance and payout of all these efforts results in delivering effective growth, increased market share and more importantly reputation in the industry.
                </p>
              </div>
            </div>
          </div>
          <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
            <img
              className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[42rem]"
              src={hmm3}
              alt="hmm1"
            />
          </div>
        </div>
      </div> */}

      <div className="relative isolate overflow-hidden bg-white py-12 sm:py-24 lg:overflow-visible px-6 lg:px-0">
        <div className="absolute inset-0 -z-10 overflow-hidden">
          <svg
            className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
            />
          </svg>
        </div>

        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
          <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-1 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
            <img
              className="w-[37rem] max-w-none bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[37rem]"
              src={hmm3}
              alt="hmm3"
              style={{borderTopRightRadius:"12px", borderBottomRightRadius:"12px"}}
            />
          </div>
          <div className="lg:col-span-2 lg:col-start-2 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-7xl lg:grid lg:grid-cols-1 lg:gap-x-8 lg:px-8">
            <div className="lg:pl-4">
              <div className="lg:max-w-lg lg:w-full">
                <p className="text-base font-semibold leading-7 text-[#fbbf24]">
                  Our Services
                </p>
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Maturity Assessment Service
                </h1>
                <p className="mt-6 text-base leading-7 text-gray-700">
                  We offer wide range of services which will help customers
                  reach top quartile performance. We offer Institution
                  strategies, comprehensive organisational performance review,
                  which cover Functional and Service reviews, Review of Digital
                  capabilities, identify opportunities for improvements covering
                  systems and processes, Change Management initiatives,
                  Leadership Development, etc. with an aim to successfully lead
                  the Transformative Journey. Our services are highly customized
                  and agile in delivery.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
