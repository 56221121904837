import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import {
  FaBatteryQuarter,
  FaChalkboardTeacher,
  FaCheck,
  FaCloudversify,
  FaDatabase,
  FaDownload,
  FaInfo,
  FaNetworkWired,
  FaReadme,
  FaSafari,
  FaSchool,
  FaServicestack,
  FaSignInAlt,
  FaStop,
  FaStreetView,
  FaVoicemail,
} from "react-icons/fa";

const Timeline = () => {
  return (
    <>
      <div className="container bg-fuchsia-50 py-24 sm:py-24">
        <div className="mx-auto lg:text-center pb-12">
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Process @HMM360
          </p>
        </div>
        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "rgb(255, 255, 255)", color: "#000" }}
            contentArrowStyle={{ borderRight: "7px solid  rgb(255, 255, 255)" }}
            date="HMM 360"
            iconStyle={{ background: "#FEBE10", color: "#fff" }}
            icon={<FaSignInAlt />}
          >
            <h3 className="vertical-timeline-element-title">1</h3>
            <h4 className="vertical-timeline-element-subtitle">Step</h4>
            <p>REGISTER</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="Self Check"
            iconStyle={{ background: "#F0E68C", color: "#fff" }}
            icon={<FaCheck />}
          >
            <h3 className="vertical-timeline-element-title">2</h3>
            <h4 className="vertical-timeline-element-subtitle">Step</h4>
            <p>CENTRAL SELF CHECK</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="Report"
            iconStyle={{ background: "#EE82EE", color: "#fff" }}
            icon={<FaDownload />}
          >
            <h3 className="vertical-timeline-element-title">3</h3>
            <h4 className="vertical-timeline-element-subtitle">Step</h4>
            <p>DOWNLOAD REPORT</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="Departmental"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            icon={<FaSchool />}
          >
            <h3 className="vertical-timeline-element-title">4</h3>
            <h4 className="vertical-timeline-element-subtitle">Step</h4>
            <p>DEPARTMENTAL SELF CHECK</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="Validation"
            iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
            icon={<FaVoicemail />}
          >
            <h3 className="vertical-timeline-element-title">5</h3>
            <h4 className="vertical-timeline-element-subtitle">Step</h4>
            <p>DEPARTMENTAL VALIDATION</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="Central"
            iconStyle={{ background: "#800080", color: "#fff" }}
            icon={<FaChalkboardTeacher />}
          >
            <h3 className="vertical-timeline-element-title">6</h3>
            <h4 className="vertical-timeline-element-subtitle">Step</h4>
            <p>CENTRAL VALIDATION</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="Internal Teams"
            iconStyle={{ background: "#33006F", color: "#fff" }}
            icon={<FaNetworkWired />}
          >
            <h3 className="vertical-timeline-element-title">7</h3>
            <h4 className="vertical-timeline-element-subtitle">Step</h4>
            <p>VARIANCE WORKSHOP</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="Reports"
            iconStyle={{ background: "#D8BFD8", color: "#fff" }}
            icon={<FaDownload />}
          >
            <h3 className="vertical-timeline-element-title">8</h3>
            <h4 className="vertical-timeline-element-subtitle">Step</h4>
            <p>DOWNLOAD REPORTS</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="Internal Vs Validation"
            iconStyle={{ background: "#00FFFF", color: "#fff" }}
            icon={<FaSafari />}
          >
            <h3 className="vertical-timeline-element-title">9</h3>
            <h4 className="vertical-timeline-element-subtitle">Step</h4>
            <p>VARIANCE WORKSHOP</p>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="Data"
            iconStyle={{ background: "#6CB4EE", color: "#fff" }}
            icon={<FaDatabase />}
          >
            <h3 className="vertical-timeline-element-title">10</h3>
            <h4 className="vertical-timeline-element-subtitle">Step</h4>
            <p>DATA MINING & ANALYSIS</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="Insights"
            iconStyle={{ background: "#0066b2", color: "#fff" }}
            icon={<FaInfo />}
          >
            <h3 className="vertical-timeline-element-title">11</h3>
            <h4 className="vertical-timeline-element-subtitle">Step</h4>
            <p>PERCEPTION & REALITY INSIGHTS</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="Understanding"
            iconStyle={{ background: "#0039a6", color: "#fff" }}
            icon={<FaReadme />}
          >
            <h3 className="vertical-timeline-element-title">12</h3>
            <h4 className="vertical-timeline-element-subtitle">Step</h4>
            <p>GAP UNDERSTANDING REPORTS</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="Quarter"
            iconStyle={{ background: "#3457D5", color: "#fff" }}
            icon={<FaBatteryQuarter />}
          >
            <h3 className="vertical-timeline-element-title">13</h3>
            <h4 className="vertical-timeline-element-subtitle">Step</h4>
            <p>QUARTERLY RECYCLE</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="Progress"
            iconStyle={{ background: "#1F75FE", color: "#fff" }}
            icon={<FaStreetView />}
          >
            <h3 className="vertical-timeline-element-title">14</h3>
            <h4 className="vertical-timeline-element-subtitle">Step</h4>
            <p>INITIATIVE REVIEWS & PROGRESS</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="PMO"
            iconStyle={{ background: "#00008B", color: "#fff" }}
            icon={<FaServicestack />}
          >
            <h3 className="vertical-timeline-element-title">15</h3>
            <h4 className="vertical-timeline-element-subtitle">Step</h4>
            <p>PMO AS A SERVICE</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="Thinking"
            iconStyle={{ background: "#6F00FF", color: "#fff" }}
            icon={<FaCloudversify />}
          >
            <h3 className="vertical-timeline-element-title">16</h3>
            <h4 className="vertical-timeline-element-subtitle">Step</h4>
            <p>DESIGN THINKING ON IMPROVEMENT</p>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            iconStyle={{ background: "#FFC72C", color: "#fff" }}
            icon={<FaStop />}
          />
        </VerticalTimeline>
      </div>
    </>
  );
};

export default Timeline;
