import React from 'react'
import AboutPage from '../components/AboutusPage'

const AboutUs = () => {
  return (
    <>
    <AboutPage />
    </>
  )
}

export default AboutUs