import React, { useRef, useEffect } from "react";
import Chart from "chart.js/auto";
import annotationPlugin from "chartjs-plugin-annotation";

Chart.register(annotationPlugin);

const Peval = ({ avgSelfAppraisal, avgCrossAppraisal }) => {
  const performanceChartRef = useRef(null);
  const performanceChartInstanceRef = useRef(null);
  const performanceChartNoLinesRef = useRef(null);

  useEffect(() => {
    //const avgSelfAppraisal = {l1C1Rating};
    //const avgCrossAppraisal = {l1C2Rating};

    const commonOptions = {
      type: "scatter",
      data: {
        datasets: [
          {
            label: "Appraisal Intersection",
            data: [
              {
                x: avgSelfAppraisal,
                y: avgCrossAppraisal,
              },
            ],
            backgroundColor: "black",
            pointStyle: "circle",
            radius: 10,
          },
          {
            label: "Midpoint",
            data: [
              {
                x: (1 + 5) / 2,
                y: (1 + 5) / 2,
              },
            ],
          },
        ],
      },
      options: {
        scales: {
          x: {
            type: "linear",
            position: "bottom",
            title: {
              display: true,
              text: "Self Appraisal - L1 C1",
            },
            min: 1,
            max: 5,
            ticks: {
              stepSize: 1,
            },
            grid: {
              display: false, // Removes the vertical grid lines
            },
            border: {
              display: false, // Removes the x-axis line
            },
          },
          y: {
            title: {
              display: true,
              text: "Cross Appraisal - L1 C2",
              color: "#f3bb2f", // Set the y-axis label color
            },
            min: 1,
            max: 5,
            ticks: {
              stepSize: 1,
            },
            grid: {
              display: false, // Removes the vertical grid lines
            },
            border: {
              display: false, // Removes the x-axis line
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          annotation: {
            annotations: {
              line1: {
                type: "line",
                mode: "vertical",
                scaleID: "x",
                value: avgSelfAppraisal,
                borderColor: "#f3bb2f",
                borderWidth: 2,
                label: {
                  enabled: true,
                  position: "top",
                },
              },
              line2: {
                type: "line",
                mode: "horizontal",
                scaleID: "y",
                value: avgCrossAppraisal,
                borderColor: "#f3bb2f",
                borderWidth: 2,
                label: {
                  enabled: true,
                  position: "right",
                },
              },
              dottedLine: {
                type: "line",
                scaleID: "x",
                value: 3,
                borderColor: "grey",
                borderWidth: 1,
                borderDash: [3, 3],
              },
              arrowLine: {
                type: "line",
                scaleID: "xy",
                xMin: avgSelfAppraisal,
                yMin: avgCrossAppraisal,
                xMax: 4.5,
                yMax: 4.5,
                borderColor: "#8AC6D1",
                borderWidth: 1,
                borderDash: [3, 3],
                arrow: {
                  angle: 45,
                  enabled: true,
                  length: 10,
                  width: 5,
                  yOffset: 5,
                },
              },
            },
          },
        },
      },
    };

    if (performanceChartInstanceRef.current) {
      performanceChartInstanceRef.current.destroy();
    }

    const ctx1 = performanceChartRef.current.getContext("2d");
    performanceChartInstanceRef.current = new Chart(ctx1, commonOptions);

    const canvas2 = performanceChartNoLinesRef.current;
    const ctx2 = canvas2.getContext("2d");

    const canvasWidth = canvas2.width - 25;
    const canvasHeight = canvas2.height - 25;

    const fixedMidpointX = ((3 - 1) / 4) * canvasWidth;
    const fixedMidpointY = canvasHeight - ((3 - 1) / 4) * canvasHeight;

    ctx2.font = "12px Space Grotesk";
    ctx2.textAlign = "center";
    ctx2.textBaseline = "middle";

    const xAxisLabel = "Self Appraisal - L1 C1";
    const yAxisLabel = "Cross Appraisal - L1 C2";

    const xAxisLabelX = canvas2.width / 2;
    const xAxisLabelY = canvas2.height - 10;

    const yAxisLabelX = 7;
    const yAxisLabelY = canvas2.height / 2;

    ctx2.fillText(xAxisLabel, xAxisLabelX, xAxisLabelY);

    ctx2.save();
    ctx2.translate(yAxisLabelX, yAxisLabelY);
    ctx2.rotate(-Math.PI / 2);
    ctx2.fillStyle = "#f3bb2f"; // Set the y-axis label color
    ctx2.fillText(yAxisLabel, 0, 0);
    ctx2.restore();

    const boxWidth = canvasWidth / 2 - 10;
    const boxHeight = canvasHeight / 2 - 10;

    const quadrants = [
      {
        text: "You are doing a good job, Stay focused",
        x: fixedMidpointX / 2,
        y: fixedMidpointY / 2,
        color: "#ef8600",
        textColor: "white",
      },
      {
        text: "You are on right track, keep continuing",
        x: (fixedMidpointX + canvasWidth) / 2,
        y: fixedMidpointY / 2,
        color: "#9f5900",
        textColor: "white",
      },
      {
        text: "Need immediate action to turn around",
        x: fixedMidpointX / 2,
        y: (fixedMidpointY + canvasHeight) / 2,
        color: "#f4d8c6",
        textColor: "black",
      },
      {
        text: "Do a fact check and work on perception",
        x: (fixedMidpointX + canvasWidth) / 2,
        y: (fixedMidpointY + canvasHeight) / 2,
        color: "#ffcd8c",
        textColor: "black",
      },
    ];

    quadrants.forEach((q) => {
      ctx2.beginPath();
      ctx2.fillStyle = q.color;
      ctx2.strokeStyle = "#f3bb2f";
      ctx2.rect(
        q.x - boxWidth / 2 + 15,
        q.y - boxHeight / 2,
        boxWidth,
        boxHeight
      );
      ctx2.fill();
      ctx2.stroke(); // Added stroke to ensure rectangle outline is visible
      wrapText(ctx2, q.text, q.x + 15, q.y, boxWidth, boxHeight, q.textColor);
    });

    const avgX = ((avgSelfAppraisal - 1) / 4) * canvasWidth;
    const avgY = canvasHeight - ((avgCrossAppraisal - 1) / 4) * canvasHeight;
    ctx2.beginPath();
    ctx2.arc(avgX, avgY, 10, 0, 2 * Math.PI, false);
    ctx2.fillStyle = "black";
    ctx2.fill();

    function wrapText(context, text, x, y, maxWidth, maxHeight, textColor) {
      context.fillStyle = textColor;
      let words = text.split(" ");
      let line = "";
      let lines = [];

      for (let i = 0; i < words.length; i++) {
        let testLine = line + words[i] + " ";
        let metrics = context.measureText(testLine);
        let testWidth = metrics.width;
        if (testWidth > maxWidth && i > 0) {
          lines.push(line);
          line = words[i] + " ";
        } else {
          line = testLine;
        }
      }
      lines.push(line);

      let startY = y - (lines.length - 1) * 0.5 * 16;

      if (startY - boxHeight / 2 < 0 || startY + boxHeight / 2 > maxHeight) {
        startY = y;
      }

      for (let i = 0; i < lines.length; i++) {
        context.fillText(lines[i], x, startY + i * 16);
      }
    }
  });

  return (
    <div
      style={{
        maxWidth: "1200px",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div>
          <canvas
            ref={performanceChartNoLinesRef}
            id="performanceChartNoLines"
            width="300"
            height="300"
          ></canvas>
        </div>
        <div>
          <canvas
            ref={performanceChartRef}
            id="performanceChart"
            width="300"
            height="300"
          ></canvas>
        </div>
      </div>
    </div>
  );
};

export default Peval;
