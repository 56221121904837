import Footer from "../layout/footer";
import Header from "../layout/header";
import ContactusPageForm from "./ContactusPageForm";
import ContactusPageHero from "./ContactusPageHero";

export default function ContactusPage() {
    return(
        <>
        <Header />

        <ContactusPageHero />
        <ContactusPageForm />
        <Footer />
        </>
    )
}