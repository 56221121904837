import React from 'react'
import ContactusPage from '../components/ContactusPage'

const Contact = () => {
  return (
    <>
    <ContactusPage />
    </>
  )
}

export default Contact