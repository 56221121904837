import React from "react";


const features = [
  {
    title: "Stable",
    number: "01",
    description:
      "Reliable, consistent performance, high level of engagement both within as well with customers, efficient resource allocation and better equipped to navigate uncertain times.",
      color: "rgb(251, 191, 36, 0.4)"
  },
  {
    title: "Progressive",
    number: "02",
    description:
      "Constantly seeking progress, autonomous teams, innovative approach, prioritize agility, high degree of adaptability, committed to continuous improvement and Transformation.",
    color: "rgb(251, 191, 36, 0.6)"
  },
  {
    title: "Improved",
    number: "03",
    description:
      "Committed to continually evolve, level-up its overall performance, anticipate and focus on the future, create niche, empower its workforce, and create positive impact to society and thrive in a dynamic business landscape.",
    color: "rgb(251, 191, 36, 0.8)"
  },
  {
    title: "Benchmarked",
    number: "04",
    description:
      "Exceptional performance in terms of quality, time and cost of delivery, innovative practices, legacy of excellence, Measurable Impact to society, Best of Technology users and pioneers and stay competitive and set industry agenda.",
    color: "rgb(251, 191, 36, 1)"
  },
];

const AboutPageContent = () => {
  return (
    <div className="bg-white py-24 sm:py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-[#fbbf24]">
            HMM 360
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Go Beyond Managed State to Benchmarked
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            The objective is to go beyond “Managed” to next stages of Maturity:
            Stable, Progressive, Improved and Benchmarked
          </p>
        </div>
        <div className="step-diagram">
          <div className="steps-container">
            {features.map((step, index) => (
              <div
                key={index}
                className="step"
                style={{ backgroundColor: step.color }}
              >
                <div className="step-content">
                  <h3>{step.title}</h3>
                  <p>{step.description}</p>
                </div>
                <div className="step-number">{step.number}</div>
              </div>
            ))}
          </div>
          <div className="arrow"></div>
        </div>
      </div>
    </div>
  );
};

export default AboutPageContent;
