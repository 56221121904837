import React from 'react';

const posts = [
  {
    id: 1,
    title: "First quartile",
    description: "Contains the lowest 25% of data points",
  },

  {
    id: 2,
    title: "Second quartile",
    description: "Represents the middle 50% of data",
  },

  {
    id: 3,
    title: "Third quartile",
    description: "Encompasses the upper 25% of data points",
  },
  {
    id: 4,
    title: "Fourth quartile",
    description: "Includes the highest 25% of data points",
  },
];

const AboutPageContent1 = ()=> {
  return (
    <div className="bg-fuchsia-50 py-24 sm:py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Top quartile performance
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Refers to achieving exceptional results or being among the top 25%
            in a particular domain. A quartile is a statistical term that
            divides a dataset into four equal parts based on the values of the
            data and Quartiles help us understand the spread and distribution of
            data
          </p>
        </div>
        <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-12 sm:pt-12 lg:mx-0 lg:max-w-none lg:grid-cols-4">
          {posts.map((post) => (
            <article
              key={post.id}
              className="flex max-w-xl flex-col card-quartile items-start justify-between"
            >
              <div className="group relative">
                <h1 className="count-number">{post.id}</h1>
                <h3 className="mt-3 title-quartile text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                  <a href={post.href}>
                    <span className="absolute inset-0" />
                    {post.title}
                  </a>
                </h3>
                <p className="mt-2 line-clamp-3 text-sm leading-6 text-gray-600">
                  {post.description}
                </p>
              </div>
            </article>
          ))}
        </div>

        <p className="mt-5 line-clamp-3 text-lg leading-8 text-gray-600">
          When we say Top-Quartile performance, we refer to being in the topmost
          of that domain or industry achieving outstanding results and be a
          reference point for others to emulate.
        </p>
      </div>
    </div>
  );
}
export default AboutPageContent1;
