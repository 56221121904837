import React from "react";
import logo from "../assets/logo.png";
import linkedin from "../assets/img/linkedin.png";


const Footer = () => {
  return (
    <footer className="text-white body-font bg-[#000]">
      <div className="container px-3 py-2 mx-auto flex items-center sm:flex-row flex-col">
        <a href="/" className="flex title-font font-medium items-center md:justify-start justify-center text-white">
        <img
                className="h-10 w-auto"
                src={logo}
                alt="logo"
              />
        </a>
        <p className="text-sm text-white sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-white-200 sm:py-2 sm:mt-0">
          © 2024 | All Rights Reserved
        </p>
        <span className="inline-flex sm:ml-auto sm:mt-0 justify-center sm:justify-start">
          <a href="/" className="ml-3 text-white hover:text-zinc-700">
            <img width={40} src={linkedin} alt="" />
          </a>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
