import Footer from "../layout/footer";
import Header from "../layout/header";


const subscriptionPlans = [
  {
    name: "Plus",
    price: 500,
    features: ["Feature 1", "Feature 2", "Feature 3"],
  },
  {
    name: "Pro",
    price: 3500,
    features: ["Feature 1", "Feature 2", "Feature 3", "Feature 4"],
  },
  {
    name: "Mature",
    price: 4999,
    features: ["Feature 1", "Feature 2", "Feature 3", "Feature 4", "Feature 5"],
  },
  {
    name: "Innovator",
    price: 9999,
    features: ["Feature 1", "Feature 2", "Feature 3", "Feature 4", "Feature 5", "Feature 6"],
  },
];

export default function Level2() {
  return (
    <>
      <Header />
      <section className="text-white body-font overflow-hidden">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-20">
            <h1 className="sm:text-4xl text-3xl font-medium title-font mb-2 text-white">
              Level 2 @HMM360
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-white">
              Choose the subscription plan that suits you best.
            </p>
          </div>
          <div className="flex flex-wrap -m-4">
            {subscriptionPlans.map((plan, index) => (
              <div key={index} className="p-4 xl:w-1/4 md:w-1/2 w-full">
                <div className="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden">
                  <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
                    {plan.name}
                  </h2>
                  <h1 className="text-5xl text-white pb-4 mb-4 border-b border-gray-200 leading-none">
                    ${plan.price}
                  </h1>
                  {plan.features.map((feature, i) => (
                    <p key={i} className="flex items-center text-white mb-2">
                      <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-amber-300 text-black rounded-full flex-shrink-0">
                        <svg
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2.5"
                          className="w-3 h-3"
                          viewBox="0 0 24 24"
                        >
                          <path d="M20 6L9 17l-5-5" />
                        </svg>
                      </span>
                      {feature}
                    </p>
                  ))}
                  <button className="flex items-center mt-auto text-black bg-amber-300 border-0 py-2 px-4 w-full focus:outline-none hover:bg-amber-600 rounded">
                    Subscribe
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      className="w-4 h-4 ml-auto"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7" />
                    </svg>
                  </button>
                  <p className="text-xs text-white mt-3">
                    Learn more about our {plan.name} plan.
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
