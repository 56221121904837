import React from 'react'
import Header from '../layout/header'
import Footer from '../layout/footer'
import AboutPageFirstHero from './AboutPageFirstHero'
import AboutPageContent from './AboutPageContent'
import AboutPageContent1 from './AboutPageContent1'
import AboutHMMHero from './AboutHMMHero'

const AboutHMM = () => {
  return (
    <>
    <Header />
    <AboutHMMHero />
    <AboutPageFirstHero />
    <AboutPageContent1 />
    <AboutPageContent />
    <Footer />
    </>
  )
}

export default AboutHMM