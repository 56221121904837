import { useState } from "react";
import "../App.css";
import Questions from "../components/questions";
import Result from "../components/result";
import Header from "../layout/header";
import Footer from "../layout/footer";
import ScrollTop from "../components/ScrollTop";

function MainComponent() {
  const [surveyData, setSurveyData] = useState(null);
  const [userData, setUserData] = useState(null);

  const handleSubmit = (data, ud) => {
    setSurveyData(data);
    setUserData(ud);
  };
  return (
    <div>
      <Header isOnboarding={false} />
      <ScrollTop />
      <div></div>
      {!surveyData && <Questions onSubmit={handleSubmit} />}
      {surveyData && <Result surveyResult={surveyData} userData={userData} />}
      <Footer />
    </div>
  );
}

export default MainComponent;
