import firebase from "firebase/compat/app";
// import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import "firebase/compat/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyD5Y6nR_Helx7_mwyVHj8OehHtcn_e8e68",
    authDomain: "hmm360-9cbf2.firebaseapp.com",
    projectId: "hmm360-9cbf2",
    storageBucket: "hmm360-9cbf2.appspot.com",
    messagingSenderId: "698378933406",
    appId: "1:698378933406:web:a3256b11e0baf6fe94d78f",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const auth = getAuth(app);
export const firestore = firebase.firestore();
export const storage = getStorage(app);
export { app, auth };
