import React from "react";
import AboutHMM from "../components/AboutHMM";

const Hmm = () => {
  return (
    <>
      <AboutHMM />
    </>
  );
};

export default Hmm;
