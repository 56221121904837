import React from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Hero from "./Hero";
import Contact from "./Contact";
import HomeSlider from "./HomeSlider";

const OnBoarding = () => {
  return (
    <>
      <Header isOnboarding={true} />

      <Hero />
      <HomeSlider />
      <Contact />
      <Footer />
    </>
  );
};

export default OnBoarding;
