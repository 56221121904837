import Footer from "../layout/footer";
import Header from "../layout/header";
import About from "./About";
import AboutPageHero from "./AboutPageHero";
import Timeline from "./Timeline";


export default function AboutPage() {
  return (
    <>
      <Header />
      <AboutPageHero />
      <About />
      <Timeline />
      <Footer />
    </>
  );
}
