import React from 'react'
import OnBoarding from '../components/onboarding'

const Home = () => {
  return (
    <>
    <OnBoarding />
    </>
  )
}

export default Home