import React, { useState, useEffect, useLayoutEffect } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useLocation,
} from "react-router-dom";
import { HashLoader } from "react-spinners";
import Home from "./Page/Home";
import Contact from "./Page/Contact";
import Survey from "./Page/Survey";
import AboutUs from "./Page/AboutUs";
import Hmm from "./Page/Hmm";
import Level2 from "./Page/Level2";
// import Pdf from "./Page/Pdf";
import "animate.css";
import ScrollToTopButton from "./components/ScrollToTopButton";
import DetailedResult from "./Page/DetailedResult";

function ScrollToTop() {
    const { pathname } = useLocation();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function App() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <Router>
                <ScrollToTop />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/survey" element={<Survey />} />
                    <Route path="/values" element={<AboutUs />} />
                    <Route path="/about" element={<Hmm />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/level-2" element={<Level2 />} />
                    <Route
                        path="/detailed-result"
                        element={<DetailedResult />}
                    />
                    {/* <Route path="/pdf" element={<Pdf />} /> */}
                </Routes>
                <ScrollToTopButton />
            </Router>

            {isLoading ? (
                <div className="loader-overlay">
                    <HashLoader
                        color={"#ff7d00"}
                        loading={isLoading}
                        size={50}
                    />
                </div>
            ) : null}
        </>
    );
}

export default App;
