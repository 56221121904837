import React, { useState } from "react";
//import { FaArrowUp, FaArrowDown } from "react-icons/fa";
// import graph1 from "../assets/img/graph/graph-1.webp";
// import graph2 from "../assets/img/graph/graph-2.webp";
// import graph3 from "../assets/img/graph/graph-3.webp";
// import graph4 from "../assets/img/graph/graph-4.webp";
// import graph5 from "../assets/img/graph/graph-5.webp";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay, Navigation } from "swiper/modules";
// import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import "swiper/css";
import "swiper/css/navigation";

/*const ArrowUp = ({ onClick }) => {
  return (
    <div className="absolute right-0 top-1/2 z-10 transform -translate-y-1/2">
      <button
        onClick={onClick}
        className="bg-amber-400 text-black rounded-full p-2 arrow-top py-4"
      >
        <FaArrowUp />
      </button>
    </div>
  );
};

const ArrowDown = ({ onClick }) => {
  return (
    <div className="absolute left-0 top-1/2 z-10 transform -translate-y-1/2">
      <button
        onClick={onClick}
        className="bg-amber-400 text-black rounded-full p-2 arrow-bottom py-4"
      >
        <FaArrowDown />
      </button>
    </div>
  );
};*/

const HomeSlider = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  // const navigate = useNavigate();

  const [isClicked, setIsClicked] = useState(false);

  // const handleSurveyLink = () => {
  //   navigate("/survey");
  // };

  const handleButtonClick = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: "smooth",
    });

    setIsClicked(true);
    setTimeout(() => {
      setIsClicked(false);
    }, 1000);
  };

  // const slides = [
  //   {
  //     title: "IS YOUR INSTITUTE ASPIRING TO IMPROVE EXISTING PROCESSES?",
  //     image: graph1,
  //   },
  //   {
  //     title: "DO YOU KNOW WHAT GETS MEASURED GETS IMPROVED?",
  //     image: graph2,
  //   },
  //   {
  //     title: "INTERNAL BASELINING IS YOUR FIRST STEP TOWARDS GROWTH!",
  //     image: graph3,
  //   },
  //   {
  //     title: "TOP QUARTILE PERFORMANCE DEMANDS MATURITY MODEL",
  //     image: graph4,
  //   },
  //   {
  //     title: "DO YOU KNOW WHAT IS YOUR INSTITUTE CURRENT MATURITY LEVEL?",
  //     image: graph5,
  //   },
  // ];

  return (
    <div className="container slider-section px-6 mx-auto">
      <div className="relative px-6 lg:px-8">
        <div className="title-slider text-center py-5">
          {/* <h3
            className="text-amber-400 text-3xl md:text-5xl leading-6 py-5 font-bold cursor-pointer hover:text-white !important hover:underline"
            onClick={handleSurveyLink}
          >
            Take a Free Trial
          </h3> */}
          <button
            className={`rounded-full bg-[#fff] px-3.5 py-2.5 text-sm font-semibold text-zinc-950 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600 animate-move-down ${
              isClicked ? "animate-move-down" : ""
            }`}
            onClick={handleButtonClick}
          >
            <span>&darr;</span>
          </button>
          &nbsp;&nbsp;
          <span
            ref={ref}
            className={`text-white ${
              inView ? "animate__animated animate__pulse" : ""
            }`}
          >
            Tell Me How?
          </span>
        </div>
        {/* <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          modules={[Autoplay, Navigation]}
          className="mySwiper"
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index} className="custom-slide">
              <div className="flex flex-col lg:flex-row items-center justify-between lg:text-left">
                <div className="lg:w-1/2 p-4">
                  <h4
                    className="text-4xl font-semibold mb-4 mx-2"
                    style={{ color: "#fff" }}
                  >
                    {slide.title}
                  </h4>
                </div>
                <div className="lg:w-1/2 p-4">
                  <img
                    src={slide.image}
                    alt={`Slide ${index + 1}`}
                    className="mx-auto lg:ml-auto"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
          <div
            className="swiper-button-prev"
            style={{ color: "#fbbf24", left: 0, zIndex: 10 }}
          ></div>
          <div
            className="swiper-button-next"
            style={{ color: "#fbbf24", right: 0, zIndex: 10 }}
          ></div>
        </Swiper> */}
        {/* <div className="mb-5 text-center">
          <button
            className="bg-[#fff] px-3.5 py-2.5 text-sm font-semibold text-zinc-950 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600 takeAfree"
            onClick={handleSurveyLink}
          >
            Take a Free Trial
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default HomeSlider;
