import { ChatBubbleLeftEllipsisIcon, HandRaisedIcon } from "@heroicons/react/24/outline";

export default function ContactusPageHero() {
  return (
    <>

<div className="overflow-hidden bg-white py-0 sm:py-0">
      <div className="mx-auto max-w-7xl px-6 lg:px-0">
          <img
            src="https://images.pexels.com/photos/3183197/pexels-photo-3183197.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt="Product screenshot"
            className="w-full max-w-none shadow-xl ring-1 ring-gray-400/10 sm:w-full md:-ml-4 lg:-ml-0"
          />
      </div>
    </div>


      <div className="relative overflow-hidden bg-white py-16 sm:py-24 lg:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
            <div className="max-w-xl lg:max-w-lg">
              <h2 className="text-3xl font-bold tracking-tight text-zinc-950 sm:text-4xl">
                Contact @HMM360
              </h2>
              <p className="mt-4 text-lg leading-8 text-gray-900">
              Thank you for your interest in contacting us. Whether you have a question about our products, need assistance with an order, or just want to provide feedback, we're here to help.
              </p>
            </div>
            <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
              <div className="flex flex-col items-start">
                <div className="rounded-md bg-zinc/5 p-2 ring-1 ring-zinc/10">
                  <ChatBubbleLeftEllipsisIcon
                    className="h-6 w-6 text-zinc-950"
                    aria-hidden="true"
                  />
                </div>
                <dt className="mt-4 font-semibold text-zinc-950">
                  Customer Support
                </dt>
                <dd className="mt-2 leading-7 text-gray-800">
                For assistance with orders, product inquiries, or any other concerns, please fill out the form below or send us an email directly at our email. Our dedicated customer support team will get back to you as soon as possible.
                </dd>
              </div>
              <div className="flex flex-col items-start">
                <div className="rounded-md bg-zinc/5 p-2 ring-1 ring-zinc/10">
                  <HandRaisedIcon
                    className="h-6 w-6 text-zinc-950"
                    aria-hidden="true"
                  />
                </div>
                <dt className="mt-4 font-semibold text-zinc-950">No spam</dt>
                <dd className="mt-2 leading-7 text-gray-800">
                At HMM360, we are committed to maintaining the privacy and security of our users' information. We have a strict "No Spam" policy in place to ensure that your email inbox remains free from unwanted or unsolicited messages.
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </>
  );
}
