import React, { useState, useRef } from "react";
import "../App.css";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, firestore } from "../firebaseConfig";
import { setDoc, doc } from "firebase/firestore";

const optionLabels = {
  excellent: "Excellent",
  satisfied: "Satisfied",
  structured: "Structured",
  manageable: "Manageable",
  notInControl: "Not In Control",
};

export const surveyData = [
  {
    name: "admission",
    question:
      "Assess the effectiveness of admission department from student point of view?",
    label: "Admission",
    options: [
      "excellent",
      "satisfied",
      "structured",
      "manageable",
      "notInControl",
    ],
    optionAnswers: [
      "The Admissions process is disorganized, with unclear program information, application requirements, and deadlines. Applicants face minimal support and unresponsiveness from staff, causing confusion and frustration.",
      "The Admission division faces organizational issues, leading to a cumbersome process for prospective students. Information on programs and deadlines is available but often unclear, with limited staff support and insufficient guidance for applicants.",
      "The admission process provides essential information on programs, requirements, and deadlines, with generally helpful and responsive staff. However, improvements are needed in clarity, accessibility of information, and support resources for applicants.",
      "The Admissions team provides an efficient, user-friendly process with detailed information on programs, requirements, and deadlines. Knowledgeable staff offer prompt support and guidance throughout the application journey.",
      "The Admission department ensures a smooth, streamlined process with comprehensive information on programs and requirements. Knowledgeable and supportive staff offer personalized assistance and promptly address queries.",
    ],
  },
  {
    name: "academics",
    question:
      "Rate the student support & contribution of academic division at your Institute?",
    label: "Academics",
    options: [
      "excellent",
      "satisfied",
      "structured",
      "manageable",
      "notInControl",
    ],
    optionAnswers: [
      "The Academics department offers outdated programs with unqualified faculty and ineffective teaching methods, resulting in disengaged students and a curriculum misaligned with industry trends, hindering career preparedness.",
      "The Academics programs are outdated and may not meet standards, with competent but less engaged faculty. The curriculum needs substantial updates to align with current industry trends.",
      "The Academics offers standard programs with competent faculty, though teaching effectiveness varies. The curriculum is adequate but needs updates to stay relevant with industry trends.",
      "The Academics division offers diverse, high-standard programs with knowledgeable faculty using effective teaching methods. The curriculum is current, relevant, and promotes critical thinking and practical skills.",
      "The Academics offers rigorous programs with high standards, taught by qualified and dedicated faculty using innovative methods. The curriculum is current, relevant, and designed to foster critical thinking, creativity, and practical skills.",
    ],
  },
  {
    name: "research",
    question: "Evaluate the Research & Paper Presentations at your Institute?",
    label: "Research",
    options: [
      "excellent",
      "satisfied",
      "structured",
      "manageable",
      "notInControl",
    ],
    optionAnswers: [
      "The Research department has minimal output due to limited faculty engagement, inadequate facilities, and insufficient funding.",
      "The Research department faces low output and impact due to limited faculty engagement in cutting-edge research, leading to fewer publications and presentations.",
      "The Research department conducts relevant projects and contributes to academia, with competent faculty researchers whose work quality and impact may vary.",
      "The Research department conducts impactful projects across various disciplines, with faculty actively publishing and presenting their work.",
      "The Research department is renowned for its groundbreaking initiatives and contributions across various fields, with experienced faculty collaborating globally.",
    ],
  },
  {
    name: "skilling",
    question:
      "Grade the Skill Readiness Initiatives taken by your institute for better employment?",
    label: "Skilling",
    options: [
      "excellent",
      "satisfied",
      "structured",
      "manageable",
      "notInControl",
    ],
    optionAnswers: [
      "The Skilling division offers few, outdated training programs. Faculty may lack qualifications and effective methods, leading to poor skill development among students.",
      "The Skilling division offers limited, often misaligned training programs with disengaged faculty, leading to subpar skill acquisition for students.",
      "The Skilling division offers industry-relevant training programs with competent faculty, though teaching effectiveness may vary.",
      "The Skilling division provides well-structured, industry-relevant training programs taught by skilled faculty using effective teaching methods.",
      "The Skilling division provides diverse, industry-relevant training programs led by experienced faculty using innovative teaching methods.",
    ],
  },
  {
    name: "exam",
    question: "Appraise the efficiency of Exam Management at your institute.",
    label: "Exam Section",
    options: [
      "excellent",
      "satisfied",
      "structured",
      "manageable",
      "notInControl",
    ],
    optionAnswers: [
      "The Exam section is disorganized, with poorly planned schedules and unreliable evaluation, causing confusion, frustration, and significant issues for students.",
      "The Exam section struggles with efficiency, leading to poorly planned schedules, student confusion, inconsistent evaluations, delays, and inaccurate grading.",
      "The Exam section manages the examination process adequately, with fair and timely evaluations, though occasional delays or inconsistencies may occur.",
      "The Exam section is efficient and well-organized, with well-planned schedules, effective communication, and a fair, timely evaluation process.",
      "The Exam section is efficient and well-organized, ensuring smooth, fair exams. Schedules are clear, and the transparent evaluation process is timely and reliable, with accurate grading and prompt results.",
    ],
  },
  {
    name: "project",
    question:
      "Classify the effectiveness of internship and projects engagement at your institute.",
    label: "Intership / Project",
    options: [
      "excellent",
      "satisfied",
      "structured",
      "manageable",
      "notInControl",
    ],
    optionAnswers: [
      "Internship/Projects: Struggles to provide valuable opportunities due to a lack of partnerships with reputable organizations, resulting in limited and often irrelevant options for students.",
      "Internship/Projects: Offers limited and low-quality opportunities due to insufficient partnerships with external organizations.",
      "Internship/Projects: Offers diverse opportunities for hands-on experience and practical skills through partnerships with various organizations, providing meaningful work relevant to students' fields of study.",
      "Internship/Projects offers diverse options for industry experience and skill development through strong collaborations with reputable organizations and a well-structured support system.",
      "Internship/Projects: Excels in providing diverse opportunities, ensuring students gain valuable industry experience and practical skills.",
    ],
  },
  {
    name: "placement",
    question:
      "Rate the support and contribution of placement cell for better jobs at campus.",
    label: "Placement",
    options: [
      "excellent",
      "satisfied",
      "structured",
      "manageable",
      "notInControl",
    ],
    optionAnswers: [
      "Placement: Fails to secure job opportunities, resulting in a low placement rate. Lacks career support services and employer relationships, leaving students unprepared and with few relevant job prospects.",
      "Placement: Struggles with low job opportunities and placement rates due to minimal career support services and limited employer relationships.",
      "Placement: Provides a satisfactory number of job opportunities and basic career support, with moderate employer relationships and potential for network expansion.",
      "Placement: Secures diverse job opportunities, maintaining a high placement rate with reputable companies. Offers comprehensive career support, strong employer relationships, and frequent recruitment events.",
      "Placement excels in securing high-quality job opportunities with top-tier companies, boasting an impressive placement rate. It offers extensive career counselling, personalized guidance, strong employer relationships, and regular campus recruitment drives.",
    ],
  },
  {
    name: "alumni",
    question: "How would you rate the contribution from your institute alumni?",
    label: "Alumni",
    options: [
      "excellent",
      "satisfied",
      "structured",
      "manageable",
      "notInControl",
    ],
    optionAnswers: [
      "Alumni Relations lacks effective communication and engagement, leading to a disconnected network with few involvement opportunities.",
      "Alumni Relations struggles with consistent communication, leading to disengagement. Infrequent, unvaried events and programs limit meaningful alumni engagement.",
      "Alumni Relations maintains contact through periodic communications and events, with moderate engagement in mentorship and networking activities, but potential for enhancement.",
      "Alumni Relations maintains effective communication and engages graduates in mentorship, networking, and fundraising through newsletters, social media, and events.",
      "Alumni Relations excels in maintaining connections with graduates through communication, events, and programs, involving them in mentorship, networking, and fundraising, which significantly contribute to the University's success.",
    ],
  },
  {
    name: "leadership",
    question:
      "How the leadership at your institute are focused on Institutional growth?",
    label: "Leadership Governance",
    options: [
      "excellent",
      "satisfied",
      "structured",
      "manageable",
      "notInControl",
    ],
    optionAnswers: [
      "Leadership lacks strategic planning, transparency, and accountability, resulting in stagnation and disorganization. Decision-making is exclusionary, ignoring key stakeholders, and the governance structure is inefficient and mismanaged.",
      "Leadership struggles with strategic planning, transparency, and accountability. It is often reactive, excludes key stakeholders, and has a weak governance structure, leading to inefficiencies and dissatisfaction.",
      "Leadership demonstrates adequate planning and transparency but lacks proactivity and innovation. Decision-making is somewhat inclusive, needing more stakeholder involvement. Governance is functional but could be more efficient.",
      "Leadership excels in strategic planning, transparency, and accountability. It addresses challenges effectively, promotes collaboration, involves stakeholders in decision-making, and ensures smooth university operations with a robust governance structure.",
      "Leadership excels in strategic planning, transparency, and accountability, fostering innovation and continuous improvement. Inclusive decision-making involves key stakeholders, and a well-defined governance structure ensures efficient university management.",
    ],
  },
  {
    name: "infra",
    question: "How will you rank the University Infrastructure currently?",
    label: "Infra & Facilities",
    options: [
      "excellent",
      "satisfied",
      "structured",
      "manageable",
      "notInControl",
    ],
    optionAnswers: [
      "Infra & Facilities: Lacks proper buildings, labs, and recreational facilities. Poor maintenance and outdated technology hinder safety, cleanliness, and the learning environment.",
      "Infra & Facilities: Struggles with outdated buildings and resources, inconsistent maintenance, and occasional safety issues. Needs significant upgrades and improved maintenance for a better campus experience.",
      "Infra & Facilities: Maintains functional, safe, and adequately equipped buildings and facilities, but lacks some modern updates. Meets basic expectations, but could improve by upgrading infrastructure and expanding facilities.",
      "Infra & Facilities: Maintains modern, well-equipped buildings and resources, providing a clean, safe, and conducive environment for learning and growth. Minor improvements, such as expanding facilities, could be made.",
      "Infra & Facilities: Maintains modern, well-equipped buildings and facilities, ensuring a safe, conducive learning environment. Provides advanced technology and resources, supports activities, and invests in infrastructure upgrades and sustainability.",
    ],
  },
  {
    name: "hr",
    question:
      "Assess the support and contribution of HR & Recruitment team for the Institutional requirements.",
    label: "HR & Recruitment",
    options: [
      "excellent",
      "satisfied",
      "structured",
      "manageable",
      "notInControl",
    ],
    optionAnswers: [
      "HR & Recruitment: Struggles to attract and retain qualified, diverse staff, with high turnover due to inefficient recruitment and lack of support services for professional development and well-being.",
      "HR & Recruitment: Faces challenges in attracting and retaining qualified staff, promoting diversity, and ensuring transparent, efficient recruitment. Employee support services are minimal and insufficient.",
      "HR & Recruitment: Attracts and retains competent staff but needs to improve diversity and inclusion. Recruitment processes are functional but could be more efficient. Employee support services are adequate but lack depth.",
      "HR & Recruitment: Successfully attracts and retains diverse, qualified staff with efficient, transparent processes and robust support services for professional development and performance evaluation.",
      "HR & Recruitment: Excels in attracting and retaining diverse, qualified staff with efficient, transparent recruitment. Provides effective support services for professional development, performance evaluation, and well-being.",
    ],
  },
  {
    name: "studentAffairs",
    question: "How active is the Students Affair at your Institute?",
    label: "Student Affairs",
    options: [
      "excellent",
      "satisfied",
      "structured",
      "manageable",
      "notInControl",
    ],
    optionAnswers: [
      "Student Affairs: Inadequate support services and non-inclusive programs leave students without essential resources, causing dissatisfaction and a lack of community.",
      "Student Affairs: Provides limited and inconsistent support services, failing to adequately address the diverse needs of students, leading to exclusion and dissatisfaction.",
      "Student Affairs offers essential services like counselling, career advising, and extracurricular activities, but their effectiveness varies. While generally inclusive, the programs could improve in meeting the diverse needs of students.",
      "Student Affairs provides comprehensive services, including counselling, career advising, and extracurricular activities, effectively supporting student development and well-being while being inclusive and responsive to diverse needs.",
      "Student Affairs: Provides comprehensive support services, inclusive programs, and extracurricular activities, enhancing student experience and fostering a sense of community.",
    ],
  },
  {
    name: "outReach",
    question:
      "Assess the current outreach programs  & initiatives at your institute.",
    label: "Outreach",
    options: [
      "excellent",
      "satisfied",
      "structured",
      "manageable",
      "notInControl",
    ],
    optionAnswers: [
      "The Outreach division lacks meaningful partnerships and effective programs, resulting in disengagement, a poor reputation, and failure to promote the University's mission and values.",
      "The Outreach division lacks meaningful partnerships and effective programs, resulting in disengagement, a poor reputation, and failure to promote the University's mission and values.",
      "The Outreach division builds and maintains some partnerships and functional programs, but their impact and inclusiveness are limited. It promotes the University's mission and values, though overall effectiveness varies.",
      "The Outreach division builds strong partnerships and impactful programs, addressing community needs and fostering positive relationships. It effectively promotes the University's mission, enhancing its reputation and visibility.",
      "The Outreach division excels in building strong partnerships and runs diverse, inclusive, and impactful programs that address community needs and foster positive relationships, effectively promoting the University's mission and values.",
    ],
  },
  {
    name: "internationalization",
    question:
      "How will you rate the internationalisation initiatives at your institute?",
    label: "Internationalization",
    options: [
      "excellent",
      "satisfied",
      "structured",
      "manageable",
      "notInControl",
    ],
    optionAnswers: [
      "Internationalization: Lacks strong partnerships with international institutions, leading to few opportunities for exchanges, research collaborations, and global learning programs.",
      "Internationalization: Limited partnerships with international institutions restrict exchanges, research collaborations, and global learning programs.",
      "Internationalization: Establishes some partnerships with international institutions, but opportunities for exchanges, research collaborations, and global learning programs are limited.",
      "Internationalization: Establishes strong international partnerships, offering many opportunities for exchanges, research collaborations, and global learning programs.",
      "Internationalization: Excels in global partnerships, providing extensive opportunities for exchanges, research collaborations, and global learning programs.",
    ],
  },
  {
    name: "Sustainability",
    question: "Rank the Sustainability initiatives taken at your institute.",
    label: "Sustainability",
    options: [
      "excellent",
      "satisfied",
      "structured",
      "manageable",
      "notInControl",
    ],
    optionAnswers: [
      "The Sustainability division fails to implement meaningful initiatives, resulting in minimal efforts in energy conservation, waste reduction, and sustainable transportation. Community engagement is virtually non-existent, leading to widespread disengagement and a lack of environmental responsibility.",
      "The Sustainability division struggles with implementing effective initiatives, showing limited and inconsistent efforts in energy conservation, waste reduction, and sustainable transportation. Community engagement through sustainability education and outreach is minimal, resulting in a lack of environmental responsibility among students, faculty, and staff.",
      "The Sustainability division implements some initiatives in energy conservation, waste reduction, and sustainable transportation, though their scope and impact are limited. Community engagement through sustainability education and outreach is present but lacks depth and comprehensiveness.",
      "The Sustainability division successfully implements energy conservation, waste reduction, and sustainable transportation initiatives across campus. The university engages the community through sustainability education and outreach, promoting environmental responsibility.",
      "The Sustainability division excels in implementing energy conservation, waste reduction, and sustainable transportation programs across campus. The university actively engages the community through sustainability education and outreach, fostering environmental responsibility among students, faculty, and staff.",
    ],
  },
];

const Questions = ({ onSubmit }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userData, setUserData] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectedType, setSelectedType] = useState("Institutions");
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef(null);

  async function submitSurvey() {
    emailjs.init("SWhRnisvVOKANZI3A");

    if (formRef?.current) {
      console.log(formRef.current);
      emailjs
        .sendForm("service_s3cfh97", "template_xys351i", formRef.current)
        .then(
          async function (response) {
            console.log("Email sent successfully", response);
            // // console.log("log");
            // console.log(selectedOptions);
            const currentUser = auth.currentUser.uid;
            await setDoc(doc(firestore, "data", currentUser), {
              userData: userData,
              result: selectedOptions,
            });
            onSubmit(selectedOptions, userData);
            showCustomAlert(
              "Success",
              "Thank you for submitting the assessment!"
            );
          },
          function (error) {
            console.log("Email failed to send", error);
            showCustomAlert(
              "Error",
              "Sorry, there was an error submitting the survey. Please try again later."
            );
          }
        );
    } else {
      showCustomAlert(
        "Error",
        "Please fill out all the required fields and provide valid inputs."
      );
    }
  }

  function showCustomAlert(title, message) {
    Swal.fire({
      title: title,
      text: message,
      icon: title.toString().toLowerCase(),
    });
  }

  const handleNext = () => {
    let isError = false;
    if (!userData) isError = true;
    if (userData?.name === "" || userData?.name === null) isError = true;
    if (
      userData?.email === "" ||
      userData?.email === null ||
      !validateEmail(userData?.email)
    )
      isError = true;
    if (isError || selectedType === "") {
      Swal.fire({
        title: "These All fields are Required!",
        text: "Name or Email is not correct!",
        icon: "question",
      });
      return;
    }
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handlePrevious = () => {
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  };

  const handleOptionChange = (option) => {
    setSelectedOptions({
      ...selectedOptions,
      [surveyData[currentQuestionIndex].question]: option,
    });
  };

  const isNextButtonDisabled = () => {
    return !selectedOptions[surveyData[currentQuestionIndex].question];
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      await signUpUser();
      await submitSurvey();
    } catch (error) {
      console.error(
        "An error occurred during sign up or survey submission:",
        error
      );
    } finally {
      setIsLoading(false);
    }
  };

  const signUpUser = async () => {
    try {
      const password = "HMM@123"; //userData.email?.split("@")[0];

      // Ensure createUserWithEmailAndPassword uses email and password
      await createUserWithEmailAndPassword(auth, userData.email, password);

      console.log("User signed up successfully.");
    } catch (error) {
      console.error("An error occurred during user sign up:", error);
    }
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
    setUserData({ ...userData, category: event.target?.value });
  };

  return (
    <>
      {isLoading && (
        <div className="divLoader">
          <svg
            className="svgLoader"
            viewBox="0 0 1024 1024"
            width="10em"
            height="10em"
          >
            <path fill="lightblue" d="PATH FOR THE LOADER ICON" />
          </svg>
        </div>
      )}
      <section className="survey-section">
        <div className="relative px-6 pt-14 lg:px-8">
          <div
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ffff00] to-[#FFD700] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>

          <div className="card mx-auto max-w-2xl py-16 sm:py-12 lg:py-14">
            <div className="card-body">
              <p className="font-xl font-bold text-base">Institute</p>
            </div>
          </div>

          <div className="card mx-auto max-w-2xl py-32 sm:py-24 lg:py-28">
            <div className="card-body">
              <p className="font-2xl font-bold text-base">
                Proceed to take this Assessment to indicate the true state of
                various Departments/Initiatives in your University or Institute
                currently?
              </p>
              <span
                className="text-xs text-amber-600"
                style={{ fontStyle: "italic" }}
              >
                (Assessments Responses should be as close to the reality, do not
                answer in terms of representing ideal state)
              </span>
            </div>
          </div>

          <div className="card mx-auto max-w-2xl py-32 sm:py-24 lg:py-28">
            <div className="card-body">
              {currentQuestionIndex === 0 && (
                <div>
                  {/* <form className="grid grid-cols-2 gap-2">
                    <label className="flex gap-2 mb-4">
                      <input
                        className="form-radio h-5 w-5 text-[#FFD700]"
                        type="radio"
                        name="business-type"
                        value="Institutions"
                        checked={selectedType === "Institutions"}
                        onChange={handleTypeChange}
                      />
                      Institutions
                    </label>
                    <label className="flex gap-2">
                      <input
                        className="form-radio h-5 w-5 text-[#FFD700]"
                        type="radio"
                        name="business-type"
                        value="Entrepreneurs"
                        checked={selectedType === "Entrepreneurs"}
                        onChange={handleTypeChange}
                        disabled
                      />
                      Entrepreneurs
                    </label>
                    <label className="flex gap-2">
                      <input
                        className="form-radio h-5 w-5 text-[#FFD700]"
                        type="radio"
                        name="business-type"
                        value="Social Enterprise"
                        checked={selectedType === "Social Enterprise"}
                        onChange={handleTypeChange}
                        disabled
                      />
                      Social Enterprise
                    </label>
                    <label className="flex gap-2">
                      <input
                        className="form-radio h-5 w-5 text-[#FFD700]"
                        type="radio"
                        name="business-type"
                        value="Family Business"
                        checked={selectedType === "Family Business"}
                        onChange={handleTypeChange}
                        disabled
                      />
                      Family Business
                    </label>
                    <label className="flex gap-2">
                      <input
                        className="form-radio h-5 w-5 text-[#FFD700]"
                        type="radio"
                        name="business-type"
                        value="Corporate"
                        checked={selectedType === "Corporate"}
                        onChange={handleTypeChange}
                        disabled
                      />
                      Corporate
                    </label>
                    <label className="flex gap-2">
                      <input
                        className="form-radio h-5 w-5 text-[#FFD700]"
                        type="radio"
                        name="business-type"
                        value="Solopreneur"
                        checked={selectedType === "Solopreneur"}
                        onChange={handleTypeChange}
                        disabled
                      />
                      Solopreneur
                    </label>
                    <br />
                  </form> */}

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <div>
                      <label htmlFor="name" className="block mb-1">
                        Name <sup style={{ color: "#ff0000" }}>*</sup>
                      </label>
                      <input
                        className="w-full px-4 py-2 border bg-transparent rounded-md focus:outline-none focus:border-blue-500"
                        type="text"
                        name="name"
                        placeholder="Enter Name"
                        value={userData?.name}
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            name: e.target.value,
                          })
                        }
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="email" className="block mb-1">
                        Email <sup style={{ color: "#ff0000" }}>*</sup>
                      </label>
                      <input
                        className="w-full px-4 py-2 border bg-transparent rounded-md focus:outline-none focus:border-blue-500"
                        type="email"
                        name="email"
                        placeholder="Enter Email"
                        value={userData?.email}
                        required
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            email: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div>
                      <label htmlFor="institue" className="block mb-1">
                        Institue Name <sup style={{ color: "#ff0000" }}>*</sup>
                      </label>
                      <input
                        className="w-full px-4 py-2 border bg-transparent rounded-md focus:outline-none focus:border-blue-500"
                        type="text"
                        name="institue"
                        placeholder="Enter Institute Name"
                        required
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            institute: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="representativeDesignation"
                        className="block mb-1"
                      >
                        Representative Designation{" "}
                        <sup style={{ color: "#ff0000" }}>*</sup>
                      </label>
                      <input
                        className="w-full px-4 py-2 border bg-transparent rounded-md focus:outline-none focus:border-blue-500"
                        type="text"
                        name="representativeDesignation"
                        placeholder="Enter Representative Designation"
                        required
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            representativeDesignation: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="assessmentHierarchy"
                        className="block mb-1"
                      >
                        Assessment Hierarchy{" "}
                        <sup style={{ color: "#ff0000" }}>*</sup>
                      </label>
                      <select
                        className="w-full px-4 py-2 border bg-transparent rounded-md focus:outline-none focus:border-blue-500"
                        name="assessmentHierarchy"
                        required
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            assessmentHierarchy: e.target.value,
                          })
                        }
                      >
                        <option value="" disabled selected>
                          Select Level
                        </option>
                        <option value="Level 1">Level 1</option>
                        {/* <option value="Level 2" disabled>
                          Level 2
                        </option>
                        <option value="Level 3" disabled>
                          Level 3
                        </option>
                        <option value="Level 4" disabled>
                          Level 4
                        </option>
                        <option value="Level 5" disabled>
                          Level 5
                        </option> */}
                      </select>
                    </div>

                    <div>
                      <label
                        htmlFor="tel"
                        className="block mb-1"
                      >
                        Contact No. (Optional){" "}
                      </label>
                      <input
                        placeholder="Enter Contact No."
                        className="w-full px-4 py-2 border bg-transparent rounded-md focus:outline-none focus:border-blue-500"
                        type="tel"
                        name="tel"
                        id="tel"
                      />
                    </div>

                    {/* <div>
                  <label htmlFor="assessmentCategory" className="block mb-1">
                    Assessment Category{" "}
                    <sup style={{ color: "#ff0000" }}>*</sup>
                  </label>
                  <input
                    className="w-full px-4 py-2 border bg-transparent rounded-md focus:outline-none focus:border-blue-500"
                    type="text"
                    name="assessmentCategory"
                    placeholder="Enter Assessment Category"
                    required
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        assessmentCategory: e.target.value,
                      })
                    }
                  />
                </div> */}
                  </div>
                  <div className="line"></div>
                </div>
              )}
              {currentQuestionIndex >= 0 &&
                currentQuestionIndex < surveyData.length && (
                  <div className="mb-4">
                    <h3 className="rounded-md bg-[#FFD700] px-3.5 py-2.5 text-sm font-semibold text-zinc-950 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">
                      {currentQuestionIndex + 1}.{" "}
                      {surveyData[currentQuestionIndex].question}
                    </h3>
                    <br />
                    <ul className="list-none p-0">
                      {surveyData[currentQuestionIndex].options.map(
                        (option, index) => (
                          <li key={index} className="mb-2">
                            <label className="inline-flex items-center">
                              <input
                                type="radio"
                                name="options"
                                value={option}
                                checked={
                                  selectedOptions[
                                    surveyData[currentQuestionIndex].question
                                  ] === option
                                }
                                onChange={() => handleOptionChange(option)}
                                className="form-radio h-5 w-5 text-[#FFD700]"
                              />
                              <span className="ml-2">
                                {optionLabels[option]}
                              </span>
                            </label>
                          </li>
                        )
                      )}
                    </ul>
                    <br />
                    <div className="flex justify-between">
                      {currentQuestionIndex > 0 && (
                        <button
                          onClick={handlePrevious}
                          className="px-4 py-2 mr-2 bg-gray-200 text-gray-700 rounded-md"
                        >
                          Previous
                        </button>
                      )}
                      {currentQuestionIndex < surveyData.length - 1 && (
                        <button
                          onClick={handleNext}
                          disabled={isNextButtonDisabled()}
                          className={`px-4 py-2 text-zinc-950 rounded-md ${
                            isNextButtonDisabled()
                              ? "bg-gray-200"
                              : "bg-[#FFD700]"
                          }`}
                        >
                          Next
                        </button>
                      )}
                      {currentQuestionIndex === surveyData.length - 1 && (
                        <button
                          disabled={isNextButtonDisabled() || isLoading}
                          onClick={handleSubmit}
                          className={`px-4 py-2 text-zinc-950 rounded-md ${
                            isNextButtonDisabled()
                              ? "bg-gray-200"
                              : "bg-[#FFD700]"
                          }`}
                        >
                          {isLoading ? (
                            <span className="loader"></span>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                )}

              <div>
                <form ref={formRef} id="surveyForm">
                  <input type="hidden" name="email" value={userData?.email} />
                  <input type="hidden" name="name" value={userData?.name} />
                  <input
                    type="hidden"
                    name="representativeDesignation"
                    value={userData?.representativeDesignation}
                  />
                  <input
                    type="hidden"
                    name="instituteName"
                    value={userData?.institute}
                  />
                  {Object.entries(surveyData).map(
                    ([question, answer, name], index) => {
                      return (
                        <input
                          type="hidden"
                          key={index}
                          name={answer?.name}
                          value={selectedOptions[answer?.question]}
                        />
                      );
                    }
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Questions;
