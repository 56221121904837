import React from 'react'
import MainComponent from '../components/maincomponent'

const Survey = () => {
  return (
    <>
    <MainComponent />
    </>
  )
}

export default Survey