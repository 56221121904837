import React, { useState } from "react";

function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  
  const handleScroll = () => {
    if (window.pageYOffset > 500) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  
  window.addEventListener("scroll", handleScroll);

  return (
    <div className={`scroll-to-top-button ${isVisible ? "visible" : ""}`}>
      <button onClick={scrollToTop}>
      &#8593;
      </button>
    </div>
  );
}

export default ScrollToTopButton;
